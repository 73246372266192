<template>
  <client-only>
    <main class="error-page">
      <ui-flexbox
        direction="column"
        align="center"
        :gap="80"
        class="error-page__inner"
      >
        <ui-flexbox
          direction="column"
          align="center"
          :gap="{ sm: 115, md: 16, lg: 16 }"
        >
          <img
            src="@base/assets/img/error-banner.webp"
            alt="error-banner"
            class="error-page__banner"
          />
          <ui-flexbox direction="column" align="center" :gap="20">
            <ui-flexbox direction="column" align="center" :gap="16">
              <ui-text :variant="textSize.heading" centered>
                {{ $t('error.title') }}
              </ui-text>
              <ui-text :variant="textSize.heading" component="h2" centered>
                {{ props.error?.statusCode ?? 404 }}
              </ui-text>
            </ui-flexbox>
            <ui-flexbox align="center" :gap="8" justify="center">
              <img
                src="@base/assets/img/logos/dob-logo-symbol.webp"
                alt="Logo"
                style="height: 30px"
              />
              <img
                src="@base/assets/img/logos/dob-logo-text.webp"
                alt="DeOrderBook"
                style="height: 30px"
              />
            </ui-flexbox>
          </ui-flexbox>
        </ui-flexbox>
        <ui-flexbox direction="column" align="center" :gap="20">
          <ui-text
            :variant="textSize.description"
            color="textSecondary"
            centered
          >
            {{ $t('error.description') }}
          </ui-text>
          <ui-button tag="a" href="/">{{ $t('error.cta') }}</ui-button>
        </ui-flexbox>
      </ui-flexbox>
    </main>
  </client-only>
</template>
<script setup lang="ts">
import type { TextVariant } from '@base/components/ui/text'
const props = defineProps({
  error: {
    type: Object,
    default: undefined,
  },
})
if (props?.error?.statusCode !== 404) {
  console.error(props)
}

const { size } = useScreenSize()
const textSizeMap: Record<
  ScreenSizeType,
  { heading: TextVariant; description: TextVariant }
> = {
  sm: {
    heading: 'h3',
    description: 'body3',
  },
  md: {
    heading: 'h1',
    description: 'body2',
  },
  lg: {
    heading: 'h1',
    description: 'body2',
  },
} as const
const textSize = computed(() => {
  return textSizeMap[size.value]
})
</script>
<style scoped lang="postcss">
/* purgecss start ignore */
.error-page {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100dvh;
  background: url('@base/assets/img/error-bg.webp') 10% 0% no-repeat;
  background-size: cover;
}
.error-page__inner {
  flex: 1;
  width: 100%;
  height: 100%;
  transform: translateY(-130px);
}
.error-page__banner {
  width: 100%;
  height: auto;
}
@media screen and (min-width: 768px) {
  .error-page {
    background: url('@base/assets/img/error-bg.webp') center center no-repeat;
    background-size: cover;
  }
  .error-page__inner {
    transform: translateY(-243px);
  }
  .error-page__banner {
    width: 704px;
    height: 797px;
  }
}
/* purgecss end ignore */
</style>
