type MetaTagsType = {
  [key: string]: { [key: string]: string }
}
type UseSetPageMetaOptions = {
  customTitle?: (title: string) => string
  customMetaTag?: (
    metaTags: MetaTagsType,
    page: string,
    params: { address?: string; hash?: string },
  ) => {
    [key: string]: string
  }
}

/**
 * Sets up and formats page meta tags based on the current route and a provided map of meta configurations.
 *
 * @param {MetaTagsType} metaTags - An object containing meta configurations for different pages, indexed by a string that represents the page identifier.
 * @param {UseSetPageMetaOptions} [options] - An optional configuration object that allows for custom title modification and meta tag overrides.
 */

export function useSetPageMeta(
  metaTags: MetaTagsType,
  options?: UseSetPageMetaOptions,
) {
  const route = useRoute()
  const config = useRuntimeConfig()
  const pageMeta = computed(() => {
    const page = route.path.split('/')[1].toUpperCase()
    if (page.length === 0 || !Object.keys(metaTags).includes(page))
      return metaTags.HOME
    return options?.customMetaTag
      ? options.customMetaTag(metaTags, page, route.params)
      : metaTags[page as keyof typeof metaTags]
  })

  const formatPageMeta = () => {
    const formatMeta: { [key: string]: () => string } = {}
    Object.keys(pageMeta.value).forEach((key) => {
      if (['title', 'ogTitle', 'twitterTitle'].includes(key)) {
        const appShortName = config.public.APP_SHORT_NAME as string
        const mainTitle = `DeOrderBook ${appShortName.charAt(0).toUpperCase() + appShortName.slice(1)}`

        formatMeta[key] = () =>
          options?.customTitle
            ? options.customTitle(pageMeta.value[key])
            : pageMeta.value[key]
              ? `${mainTitle} | ${pageMeta.value[key]}`
              : mainTitle
      } else {
        formatMeta[key] = () => pageMeta.value[key]
      }
    })
    return formatMeta
  }

  if (Object.keys(metaTags).length === 1) {
    useServerSeoMeta(formatPageMeta()) // better performance
  } else {
    useSeoMeta(formatPageMeta())
  }
}
