import type { SubgraphOptions } from '@deorderbook/sdk/store/subgraph'
import { setOptions } from '@deorderbook/sdk'
import { type ChainId } from '@deorderbook/contracts'
import { setBackend } from '@deorderbook/backend-api'

export function useApp() {
  const appStore = useAppStore()
  const { isSetOption } = toRefs(appStore)

  /**
   * init sdk and app
   * @param {ChainId} [chainId] If chainId is provided, id will be forced to be used
   */
  const setupApp = async (chainId?: ChainId) => {
    const config = useRuntimeConfig()
    const { setCurrentChain, currentChain } = useChains()
    await setCurrentChain(chainId)
    if (currentChain.value === undefined) return

    // NOTE: useBackendClient should after setCurrentChain
    const { clientURLFromEnv } = useBackendClient()

    const subgraphVersion = (config.public.SUBGRAPH_VERSION ??
      'default') as SubgraphOptions['version']

    setOptions(
      {
        network: currentChain.value?.chainId,
        rpcKey: currentChain.value?.rpcKey,
      },
      {
        debug:
          config.public.MODE !== 'locale' && // remove this line if you want to see the debug log in local
          (config.public.NODE_ENV === 'development' ||
            config.public.VERCEL_ENV === 'preview'),
        subgraphOptions: {
          version: subgraphVersion,
        },
      },
    )
    setBackend(currentChain.value?.chainId, {
      // urlFromEnv will override the default value of the current chain's backend url.
      urlFromEnv: clientURLFromEnv.value,
    })
    isSetOption.value = true
  }

  return {
    ...toRefs(appStore),
    setupApp,
    setIsLogged: appStore.setIsLogged,
    setIsMobile: appStore.setIsMobile,
  }
}
