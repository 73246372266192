import { getTokenBySymbol } from '@deorderbook/sdk'

export function getDefaultParams() {
  const originalDOBSymbol = getTokenBySymbol('DOB').originalSymbol
  const originalSellTokenSymbol = getTokenBySymbol('sellToken').originalSymbol
  const originalSellTokenHODLSymbol =
    getTokenBySymbol('sellTokenHODL').originalSymbol
  return {
    DOB: originalDOBSymbol,
    sellToken: originalSellTokenSymbol,
    sellTokenHODL: originalSellTokenHODLSymbol,
  }
}

/**
 * useI18n with default parameters.
 */
export function useCustomI18n() {
  const { t: originalT, ...rest } = useI18n()

  const t: typeof originalT = (key: string, params = {}) => {
    const defaultParams = getDefaultParams()
    const finalParams = Object.assign(defaultParams, params) // { ...defaultParams, ...params }
    return originalT(key, finalParams)
  }

  return { ...rest, t }
}
