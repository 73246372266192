// Wrapping NuxtPage in div is used to fix the problem of being unable to jump after packaging, https://github.com/nuxt/framework/issues/2985
import { Big } from 'big.js'
import dayjs from 'dayjs'
import { en, ko, ru } from 'element-plus/es/locale/index'
import { overrideI18nTranslate } from '@base/extends/i18n/composables/overrideI18nTranslate'

export const initBigJs = () => {
  Big.RM = 0
  Big.PE = 50
}
initBigJs()

const elementPlusI18nEnum = {
  en,
  ko,
  ru,
}
type ElementPlusI18nEnumKeys = keyof typeof elementPlusI18nEnum

export function useAppInit() {
  overrideI18nTranslate()

  const route = useRoute()
  const currentLocale =
    (route?.matched[0]?.name as string)?.split('___')[1] || 'en'
  const { locale, setLocale } = useCustomI18n()
  setLocale(currentLocale)
  dayjs.locale(locale.value)
  const locales = computed(() => {
    return elementPlusI18nEnum[locale.value as ElementPlusI18nEnumKeys] || en
  })
  return {
    locales,
  }
}
