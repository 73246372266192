import type { Options } from 'vue-gtag-next'
import VueGtag, { trackRouter } from 'vue-gtag-next'

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig()
  nuxtApp.vueApp.use(VueGtag, {
    property: {
      id: config?.public?.GA_MEASUREMENT_ID || '',
    },
    isEnabled: config?.public?.NODE_ENV === 'production',
  } as Options)
  trackRouter(useRouter())
})
