<template>
  <el-config-provider :locale="locales">
    <NuxtLayout>
      <div class="app">
        <NuxtPage />
      </div>
    </NuxtLayout>
  </el-config-provider>
</template>
<script setup lang="ts">
// A div wrapper around NuxtPage is used to fix the issue where it can't navigate after packaging, https://github.com/nuxt/framework/issues/2985
import META_TAGS from './utils/meta-tags'

const { setupApp } = useApp()
await setupApp()

useSetPageMeta(META_TAGS, {
  customTitle: (title: string) => `DeOrderBook | ${title}`,
})

const { locales } = useAppInit()
// NOTE: do not add code after useAPPInit
</script>
<style lang="postcss" scoped>
.app {
  display: flex;
  flex: 1;
  flex-direction: column;
}
</style>
