import { useChainStore } from '@base/store/chain'

export function useChains() {
  const {
    defaultProvider,
    setDefaultProvider,
    currentChain,
    isReady,
    chains,
    chainId,
    rpcHost,
    getChainBy,
    setCurrentChain,
  } = toRefs(useChainStore())

  return {
    defaultProvider,
    setDefaultProvider: unref(setDefaultProvider),
    chains,
    getChainBy: unref(getChainBy),
    isReady,
    rpcHost,
    currentChain,
    setCurrentChain: unref(setCurrentChain),
    chainId,
  }
}
