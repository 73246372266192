import { default as indexTHM0MiqjeOMeta } from "/vercel/path0/apps/main/pages/dashboard/index.vue?macro=true";
import { default as BasicAssetsGCqhSqwtGRMeta } from "/vercel/path0/apps/main/pages/dashboard/items/my-assets/basic-assets/BasicAssets.vue?macro=true";
import { default as index8PKFWdORSDMeta } from "/vercel/path0/apps/main/pages/dashboard/items/my-assets/basic-assets/index.ts?macro=true";
import { default as TokenAssetshZxd548VihMeta } from "/vercel/path0/apps/main/pages/dashboard/items/my-assets/basic-assets/TokenAssets.vue?macro=true";
import { default as indexOfnnL3CfRwMeta } from "/vercel/path0/apps/main/pages/dashboard/items/my-assets/index.ts?macro=true";
import { default as indexqffTF90MbUMeta } from "/vercel/path0/apps/main/pages/dashboard/items/my-assets/leaderboard/index.ts?macro=true";
import { default as LeaderboardmgLjDmA7Y6Meta } from "/vercel/path0/apps/main/pages/dashboard/items/my-assets/leaderboard/Leaderboard.vue?macro=true";
import { default as LeaderboardTableC7hri33o9oMeta } from "/vercel/path0/apps/main/pages/dashboard/items/my-assets/leaderboard/LeaderboardTable.vue?macro=true";
import { default as MyAssetsoYH3WJPSXbMeta } from "/vercel/path0/apps/main/pages/dashboard/items/my-assets/MyAssets.vue?macro=true";
import { default as indexVgR9CSJNydMeta } from "/vercel/path0/apps/main/pages/dashboard/items/my-assets/sniper-assets/index.ts?macro=true";
import { default as SniperAssetsaP96M9qqRwMeta } from "/vercel/path0/apps/main/pages/dashboard/items/my-assets/sniper-assets/SniperAssets.vue?macro=true";
import { default as SniperTable2giuTvAYrgMeta } from "/vercel/path0/apps/main/pages/dashboard/items/my-assets/sniper-assets/SniperTable.vue?macro=true";
import { default as SniperTableOperationsK62QerVJNdMeta } from "/vercel/path0/apps/main/pages/dashboard/items/my-assets/sniper-assets/SniperTableOperations.vue?macro=true";
import { default as indexBcTFITARB0Meta } from "/vercel/path0/apps/main/pages/dashboard/items/my-assets/total-net-assets/index.ts?macro=true";
import { default as TNAHeaderoCWV2cLrRQMeta } from "/vercel/path0/apps/main/pages/dashboard/items/my-assets/total-net-assets/TNAHeader.vue?macro=true";
import { default as TotalNetAssetsEGRZHnNnL8Meta } from "/vercel/path0/apps/main/pages/dashboard/items/my-assets/total-net-assets/TotalNetAssets.vue?macro=true";
import { default as BulletAssetsrAOXwR5DOsMeta } from "/vercel/path0/apps/main/pages/dashboard/items/my-bullet/BulletAssets.vue?macro=true";
import { default as BulletStatusr1tdwL2EM1Meta } from "/vercel/path0/apps/main/pages/dashboard/items/my-bullet/BulletStatus.vue?macro=true";
import { default as BulletTable3whTPnCD3jMeta } from "/vercel/path0/apps/main/pages/dashboard/items/my-bullet/BulletTable.vue?macro=true";
import { default as BulletTableOperations04rhK9qQLXMeta } from "/vercel/path0/apps/main/pages/dashboard/items/my-bullet/BulletTableOperations.vue?macro=true";
import { default as indexojQVjQfugwMeta } from "/vercel/path0/apps/main/pages/dashboard/items/my-bullet/index.ts?macro=true";
import { default as MyBullet9RsaBoQsGRMeta } from "/vercel/path0/apps/main/pages/dashboard/items/my-bullet/MyBullet.vue?macro=true";
import { default as indexOMwe2AuZO5Meta } from "/vercel/path0/apps/main/pages/index/index.vue?macro=true";
import { default as indexYN6BSuswbjMeta } from "/vercel/path0/apps/main/pages/lock/index.vue?macro=true";
import { default as AvailableSectionOKPJhEhwwkMeta } from "/vercel/path0/apps/main/pages/lock/items/available-section/AvailableSection.vue?macro=true";
import { default as AvailableSectionHeaderSGejVf0MroMeta } from "/vercel/path0/apps/main/pages/lock/items/available-section/AvailableSectionHeader.vue?macro=true";
import { default as BulletListDialogcJYpo9nyXzMeta } from "/vercel/path0/apps/main/pages/lock/items/available-section/bullet-list-dialog/BulletListDialog.vue?macro=true";
import { default as indexSxHN0hkeGwMeta } from "/vercel/path0/apps/main/pages/lock/items/available-section/bullet-list-dialog/index.ts?macro=true";
import { default as BulletRaffleInfoWo57hITfPcMeta } from "/vercel/path0/apps/main/pages/lock/items/available-section/bullet-raffle-info/BulletRaffleInfo.vue?macro=true";
import { default as BulletRaffleInfoHeaderEGwfcfIEATMeta } from "/vercel/path0/apps/main/pages/lock/items/available-section/bullet-raffle-info/BulletRaffleInfoHeader.vue?macro=true";
import { default as BulletRaffleInfoItemGn8q8KRGkgMeta } from "/vercel/path0/apps/main/pages/lock/items/available-section/bullet-raffle-info/BulletRaffleInfoItem.vue?macro=true";
import { default as BulletRaffleInfoItemsHyzOVk5XuLMeta } from "/vercel/path0/apps/main/pages/lock/items/available-section/bullet-raffle-info/BulletRaffleInfoItems.vue?macro=true";
import { default as indexUykrjtUlL0Meta } from "/vercel/path0/apps/main/pages/lock/items/available-section/bullet-raffle-info/index.ts?macro=true";
import { default as indexlXTOyrCDxlMeta } from "/vercel/path0/apps/main/pages/lock/items/available-section/index.ts?macro=true";
import { default as index6L17NXFseYMeta } from "/vercel/path0/apps/main/pages/lock/items/lock-status-section/index.ts?macro=true";
import { default as BulletRewardRowj5WEhKJiX6Meta } from "/vercel/path0/apps/main/pages/lock/items/lock-status-section/lock-simulator/BulletRewardRow.vue?macro=true";
import { default as indexRSiOiN2O3kMeta } from "/vercel/path0/apps/main/pages/lock/items/lock-status-section/lock-simulator/index.ts?macro=true";
import { default as LockSimulatorlyTqEewAE6Meta } from "/vercel/path0/apps/main/pages/lock/items/lock-status-section/lock-simulator/LockSimulator.vue?macro=true";
import { default as NewSharingRow1t8w0BRtO1Meta } from "/vercel/path0/apps/main/pages/lock/items/lock-status-section/lock-simulator/NewSharingRow.vue?macro=true";
import { default as TodaysSharingRow1K7xRqdHWUMeta } from "/vercel/path0/apps/main/pages/lock/items/lock-status-section/lock-simulator/TodaysSharingRow.vue?macro=true";
import { default as TotalLockAmountRowikj2S7GBlbMeta } from "/vercel/path0/apps/main/pages/lock/items/lock-status-section/lock-simulator/TotalLockAmountRow.vue?macro=true";
import { default as indext1S1qH4K3hMeta } from "/vercel/path0/apps/main/pages/lock/items/lock-status-section/locked-info/index.ts?macro=true";
import { default as LockedInfoXf66gD6a74Meta } from "/vercel/path0/apps/main/pages/lock/items/lock-status-section/locked-info/LockedInfo.vue?macro=true";
import { default as LockedInfoChartJvX6Ui6xyqMeta } from "/vercel/path0/apps/main/pages/lock/items/lock-status-section/locked-info/LockedInfoChart.vue?macro=true";
import { default as LockedInfoHeaderewta5ITW77Meta } from "/vercel/path0/apps/main/pages/lock/items/lock-status-section/locked-info/LockedInfoHeader.vue?macro=true";
import { default as LockedInfoStatJh7o8cc1RjMeta } from "/vercel/path0/apps/main/pages/lock/items/lock-status-section/locked-info/LockedInfoStat.vue?macro=true";
import { default as LockedInfoStatsveJnRxb9nEMeta } from "/vercel/path0/apps/main/pages/lock/items/lock-status-section/locked-info/LockedInfoStats.vue?macro=true";
import { default as LockStatusSectionjQJp4PL0JqMeta } from "/vercel/path0/apps/main/pages/lock/items/lock-status-section/LockStatusSection.vue?macro=true";
import { default as BulletRewardsHnE0Miaw0FMeta } from "/vercel/path0/apps/main/pages/lock/items/rewards-section/bullet-rewards/BulletRewards.vue?macro=true";
import { default as BulletRewardsInfo5ekN0ABrX3Meta } from "/vercel/path0/apps/main/pages/lock/items/rewards-section/bullet-rewards/BulletRewardsInfo.vue?macro=true";
import { default as indexAEWx2FlufEMeta } from "/vercel/path0/apps/main/pages/lock/items/rewards-section/bullet-rewards/index.ts?macro=true";
import { default as indextxEZTRoRruMeta } from "/vercel/path0/apps/main/pages/lock/items/rewards-section/index.ts?macro=true";
import { default as LockingRewardsYoB9TJgxDlMeta } from "/vercel/path0/apps/main/pages/lock/items/rewards-section/locking-rewards/LockingRewards.vue?macro=true";
import { default as LockingRewardsInfoUUQ0FRsurjMeta } from "/vercel/path0/apps/main/pages/lock/items/rewards-section/locking-rewards/LockingRewardsInfo.vue?macro=true";
import { default as TokenRewardItemIQh0sFXPRCMeta } from "/vercel/path0/apps/main/pages/lock/items/rewards-section/locking-rewards/TokenRewardItem.vue?macro=true";
import { default as TokenRewards6SzrIRsggvMeta } from "/vercel/path0/apps/main/pages/lock/items/rewards-section/locking-rewards/TokenRewards.vue?macro=true";
import { default as RewardsSectionK3esdi1OyoMeta } from "/vercel/path0/apps/main/pages/lock/items/rewards-section/RewardsSection.vue?macro=true";
import { default as indexB1p6RFr3OPMeta } from "/vercel/path0/apps/main/pages/lock/items/stats-section/index.ts?macro=true";
import { default as StatsSectionHCqjPmJif5Meta } from "/vercel/path0/apps/main/pages/lock/items/stats-section/StatsSection.vue?macro=true";
import { default as indexB871uAzj5nMeta } from "/vercel/path0/apps/main/pages/pools/index.ts?macro=true";
import { default as indexV32RJAuD0XMeta } from "/vercel/path0/apps/main/pages/pools/items/my-pools/index.ts?macro=true";
import { default as MyPoolsry8nz5FoZ9Meta } from "/vercel/path0/apps/main/pages/pools/items/my-pools/MyPools.vue?macro=true";
import { default as PoolItemvX1Qi8xAVYMeta } from "/vercel/path0/apps/main/pages/pools/items/my-pools/PoolItem.vue?macro=true";
import { default as PoolsEHyqAhsO2aMeta } from "/vercel/path0/apps/main/pages/pools/items/my-pools/Pools.vue?macro=true";
import { default as PoolsGridFDFXSDIVffMeta } from "/vercel/path0/apps/main/pages/pools/items/my-pools/PoolsGrid.vue?macro=true";
import { default as index6bVjbFgi2HMeta } from "/vercel/path0/apps/main/pages/pools/items/pool-section-header/index.ts?macro=true";
import { default as PoolFilterAndSortByWvgh7JAncTMeta } from "/vercel/path0/apps/main/pages/pools/items/pool-section-header/PoolFilterAndSortBy.vue?macro=true";
import { default as PoolSectionHeader3UX1FVQFBfMeta } from "/vercel/path0/apps/main/pages/pools/items/pool-section-header/PoolSectionHeader.vue?macro=true";
import { default as indexLb3SqFmXm4Meta } from "/vercel/path0/apps/main/pages/pools/items/staking-pools/index.ts?macro=true";
import { default as PoolItemvK5d3mAQNYMeta } from "/vercel/path0/apps/main/pages/pools/items/staking-pools/PoolItem.vue?macro=true";
import { default as PoolsScqq0STlijMeta } from "/vercel/path0/apps/main/pages/pools/items/staking-pools/Pools.vue?macro=true";
import { default as PoolsGridehPciAuJy1Meta } from "/vercel/path0/apps/main/pages/pools/items/staking-pools/PoolsGrid.vue?macro=true";
import { default as StakingPoolsbp24ukCJIfMeta } from "/vercel/path0/apps/main/pages/pools/items/staking-pools/StakingPools.vue?macro=true";
import { default as PoolsKZpAvvQd9EMeta } from "/vercel/path0/apps/main/pages/pools/Pools.vue?macro=true";
export default [
  {
    name: indexTHM0MiqjeOMeta?.name ?? "dashboard___en",
    path: indexTHM0MiqjeOMeta?.path ?? "/dashboard",
    meta: indexTHM0MiqjeOMeta || {},
    alias: indexTHM0MiqjeOMeta?.alias || [],
    redirect: indexTHM0MiqjeOMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: indexTHM0MiqjeOMeta?.name ?? "dashboard___ru",
    path: indexTHM0MiqjeOMeta?.path ?? "/ru/dashboard",
    meta: indexTHM0MiqjeOMeta || {},
    alias: indexTHM0MiqjeOMeta?.alias || [],
    redirect: indexTHM0MiqjeOMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: BasicAssetsGCqhSqwtGRMeta?.name ?? "dashboard-items-my-assets-basic-assets-BasicAssets___en",
    path: BasicAssetsGCqhSqwtGRMeta?.path ?? "/dashboard/items/my-assets/basic-assets/BasicAssets",
    meta: BasicAssetsGCqhSqwtGRMeta || {},
    alias: BasicAssetsGCqhSqwtGRMeta?.alias || [],
    redirect: BasicAssetsGCqhSqwtGRMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/items/my-assets/basic-assets/BasicAssets.vue").then(m => m.default || m)
  },
  {
    name: BasicAssetsGCqhSqwtGRMeta?.name ?? "dashboard-items-my-assets-basic-assets-BasicAssets___ru",
    path: BasicAssetsGCqhSqwtGRMeta?.path ?? "/ru/dashboard/items/my-assets/basic-assets/BasicAssets",
    meta: BasicAssetsGCqhSqwtGRMeta || {},
    alias: BasicAssetsGCqhSqwtGRMeta?.alias || [],
    redirect: BasicAssetsGCqhSqwtGRMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/items/my-assets/basic-assets/BasicAssets.vue").then(m => m.default || m)
  },
  {
    name: index8PKFWdORSDMeta?.name ?? "dashboard-items-my-assets-basic-assets___en",
    path: index8PKFWdORSDMeta?.path ?? "/dashboard/items/my-assets/basic-assets",
    meta: index8PKFWdORSDMeta || {},
    alias: index8PKFWdORSDMeta?.alias || [],
    redirect: index8PKFWdORSDMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/items/my-assets/basic-assets/index.ts").then(m => m.default || m)
  },
  {
    name: index8PKFWdORSDMeta?.name ?? "dashboard-items-my-assets-basic-assets___ru",
    path: index8PKFWdORSDMeta?.path ?? "/ru/dashboard/items/my-assets/basic-assets",
    meta: index8PKFWdORSDMeta || {},
    alias: index8PKFWdORSDMeta?.alias || [],
    redirect: index8PKFWdORSDMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/items/my-assets/basic-assets/index.ts").then(m => m.default || m)
  },
  {
    name: TokenAssetshZxd548VihMeta?.name ?? "dashboard-items-my-assets-basic-assets-TokenAssets___en",
    path: TokenAssetshZxd548VihMeta?.path ?? "/dashboard/items/my-assets/basic-assets/TokenAssets",
    meta: TokenAssetshZxd548VihMeta || {},
    alias: TokenAssetshZxd548VihMeta?.alias || [],
    redirect: TokenAssetshZxd548VihMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/items/my-assets/basic-assets/TokenAssets.vue").then(m => m.default || m)
  },
  {
    name: TokenAssetshZxd548VihMeta?.name ?? "dashboard-items-my-assets-basic-assets-TokenAssets___ru",
    path: TokenAssetshZxd548VihMeta?.path ?? "/ru/dashboard/items/my-assets/basic-assets/TokenAssets",
    meta: TokenAssetshZxd548VihMeta || {},
    alias: TokenAssetshZxd548VihMeta?.alias || [],
    redirect: TokenAssetshZxd548VihMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/items/my-assets/basic-assets/TokenAssets.vue").then(m => m.default || m)
  },
  {
    name: indexOfnnL3CfRwMeta?.name ?? "dashboard-items-my-assets___en",
    path: indexOfnnL3CfRwMeta?.path ?? "/dashboard/items/my-assets",
    meta: indexOfnnL3CfRwMeta || {},
    alias: indexOfnnL3CfRwMeta?.alias || [],
    redirect: indexOfnnL3CfRwMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/items/my-assets/index.ts").then(m => m.default || m)
  },
  {
    name: indexOfnnL3CfRwMeta?.name ?? "dashboard-items-my-assets___ru",
    path: indexOfnnL3CfRwMeta?.path ?? "/ru/dashboard/items/my-assets",
    meta: indexOfnnL3CfRwMeta || {},
    alias: indexOfnnL3CfRwMeta?.alias || [],
    redirect: indexOfnnL3CfRwMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/items/my-assets/index.ts").then(m => m.default || m)
  },
  {
    name: indexqffTF90MbUMeta?.name ?? "dashboard-items-my-assets-leaderboard___en",
    path: indexqffTF90MbUMeta?.path ?? "/dashboard/items/my-assets/leaderboard",
    meta: indexqffTF90MbUMeta || {},
    alias: indexqffTF90MbUMeta?.alias || [],
    redirect: indexqffTF90MbUMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/items/my-assets/leaderboard/index.ts").then(m => m.default || m)
  },
  {
    name: indexqffTF90MbUMeta?.name ?? "dashboard-items-my-assets-leaderboard___ru",
    path: indexqffTF90MbUMeta?.path ?? "/ru/dashboard/items/my-assets/leaderboard",
    meta: indexqffTF90MbUMeta || {},
    alias: indexqffTF90MbUMeta?.alias || [],
    redirect: indexqffTF90MbUMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/items/my-assets/leaderboard/index.ts").then(m => m.default || m)
  },
  {
    name: LeaderboardmgLjDmA7Y6Meta?.name ?? "dashboard-items-my-assets-leaderboard-Leaderboard___en",
    path: LeaderboardmgLjDmA7Y6Meta?.path ?? "/dashboard/items/my-assets/leaderboard/Leaderboard",
    meta: LeaderboardmgLjDmA7Y6Meta || {},
    alias: LeaderboardmgLjDmA7Y6Meta?.alias || [],
    redirect: LeaderboardmgLjDmA7Y6Meta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/items/my-assets/leaderboard/Leaderboard.vue").then(m => m.default || m)
  },
  {
    name: LeaderboardmgLjDmA7Y6Meta?.name ?? "dashboard-items-my-assets-leaderboard-Leaderboard___ru",
    path: LeaderboardmgLjDmA7Y6Meta?.path ?? "/ru/dashboard/items/my-assets/leaderboard/Leaderboard",
    meta: LeaderboardmgLjDmA7Y6Meta || {},
    alias: LeaderboardmgLjDmA7Y6Meta?.alias || [],
    redirect: LeaderboardmgLjDmA7Y6Meta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/items/my-assets/leaderboard/Leaderboard.vue").then(m => m.default || m)
  },
  {
    name: LeaderboardTableC7hri33o9oMeta?.name ?? "dashboard-items-my-assets-leaderboard-LeaderboardTable___en",
    path: LeaderboardTableC7hri33o9oMeta?.path ?? "/dashboard/items/my-assets/leaderboard/LeaderboardTable",
    meta: LeaderboardTableC7hri33o9oMeta || {},
    alias: LeaderboardTableC7hri33o9oMeta?.alias || [],
    redirect: LeaderboardTableC7hri33o9oMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/items/my-assets/leaderboard/LeaderboardTable.vue").then(m => m.default || m)
  },
  {
    name: LeaderboardTableC7hri33o9oMeta?.name ?? "dashboard-items-my-assets-leaderboard-LeaderboardTable___ru",
    path: LeaderboardTableC7hri33o9oMeta?.path ?? "/ru/dashboard/items/my-assets/leaderboard/LeaderboardTable",
    meta: LeaderboardTableC7hri33o9oMeta || {},
    alias: LeaderboardTableC7hri33o9oMeta?.alias || [],
    redirect: LeaderboardTableC7hri33o9oMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/items/my-assets/leaderboard/LeaderboardTable.vue").then(m => m.default || m)
  },
  {
    name: MyAssetsoYH3WJPSXbMeta?.name ?? "dashboard-items-my-assets-MyAssets___en",
    path: MyAssetsoYH3WJPSXbMeta?.path ?? "/dashboard/items/my-assets/MyAssets",
    meta: MyAssetsoYH3WJPSXbMeta || {},
    alias: MyAssetsoYH3WJPSXbMeta?.alias || [],
    redirect: MyAssetsoYH3WJPSXbMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/items/my-assets/MyAssets.vue").then(m => m.default || m)
  },
  {
    name: MyAssetsoYH3WJPSXbMeta?.name ?? "dashboard-items-my-assets-MyAssets___ru",
    path: MyAssetsoYH3WJPSXbMeta?.path ?? "/ru/dashboard/items/my-assets/MyAssets",
    meta: MyAssetsoYH3WJPSXbMeta || {},
    alias: MyAssetsoYH3WJPSXbMeta?.alias || [],
    redirect: MyAssetsoYH3WJPSXbMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/items/my-assets/MyAssets.vue").then(m => m.default || m)
  },
  {
    name: indexVgR9CSJNydMeta?.name ?? "dashboard-items-my-assets-sniper-assets___en",
    path: indexVgR9CSJNydMeta?.path ?? "/dashboard/items/my-assets/sniper-assets",
    meta: indexVgR9CSJNydMeta || {},
    alias: indexVgR9CSJNydMeta?.alias || [],
    redirect: indexVgR9CSJNydMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/items/my-assets/sniper-assets/index.ts").then(m => m.default || m)
  },
  {
    name: indexVgR9CSJNydMeta?.name ?? "dashboard-items-my-assets-sniper-assets___ru",
    path: indexVgR9CSJNydMeta?.path ?? "/ru/dashboard/items/my-assets/sniper-assets",
    meta: indexVgR9CSJNydMeta || {},
    alias: indexVgR9CSJNydMeta?.alias || [],
    redirect: indexVgR9CSJNydMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/items/my-assets/sniper-assets/index.ts").then(m => m.default || m)
  },
  {
    name: SniperAssetsaP96M9qqRwMeta?.name ?? "dashboard-items-my-assets-sniper-assets-SniperAssets___en",
    path: SniperAssetsaP96M9qqRwMeta?.path ?? "/dashboard/items/my-assets/sniper-assets/SniperAssets",
    meta: SniperAssetsaP96M9qqRwMeta || {},
    alias: SniperAssetsaP96M9qqRwMeta?.alias || [],
    redirect: SniperAssetsaP96M9qqRwMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/items/my-assets/sniper-assets/SniperAssets.vue").then(m => m.default || m)
  },
  {
    name: SniperAssetsaP96M9qqRwMeta?.name ?? "dashboard-items-my-assets-sniper-assets-SniperAssets___ru",
    path: SniperAssetsaP96M9qqRwMeta?.path ?? "/ru/dashboard/items/my-assets/sniper-assets/SniperAssets",
    meta: SniperAssetsaP96M9qqRwMeta || {},
    alias: SniperAssetsaP96M9qqRwMeta?.alias || [],
    redirect: SniperAssetsaP96M9qqRwMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/items/my-assets/sniper-assets/SniperAssets.vue").then(m => m.default || m)
  },
  {
    name: SniperTable2giuTvAYrgMeta?.name ?? "dashboard-items-my-assets-sniper-assets-SniperTable___en",
    path: SniperTable2giuTvAYrgMeta?.path ?? "/dashboard/items/my-assets/sniper-assets/SniperTable",
    meta: SniperTable2giuTvAYrgMeta || {},
    alias: SniperTable2giuTvAYrgMeta?.alias || [],
    redirect: SniperTable2giuTvAYrgMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/items/my-assets/sniper-assets/SniperTable.vue").then(m => m.default || m)
  },
  {
    name: SniperTable2giuTvAYrgMeta?.name ?? "dashboard-items-my-assets-sniper-assets-SniperTable___ru",
    path: SniperTable2giuTvAYrgMeta?.path ?? "/ru/dashboard/items/my-assets/sniper-assets/SniperTable",
    meta: SniperTable2giuTvAYrgMeta || {},
    alias: SniperTable2giuTvAYrgMeta?.alias || [],
    redirect: SniperTable2giuTvAYrgMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/items/my-assets/sniper-assets/SniperTable.vue").then(m => m.default || m)
  },
  {
    name: SniperTableOperationsK62QerVJNdMeta?.name ?? "dashboard-items-my-assets-sniper-assets-SniperTableOperations___en",
    path: SniperTableOperationsK62QerVJNdMeta?.path ?? "/dashboard/items/my-assets/sniper-assets/SniperTableOperations",
    meta: SniperTableOperationsK62QerVJNdMeta || {},
    alias: SniperTableOperationsK62QerVJNdMeta?.alias || [],
    redirect: SniperTableOperationsK62QerVJNdMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/items/my-assets/sniper-assets/SniperTableOperations.vue").then(m => m.default || m)
  },
  {
    name: SniperTableOperationsK62QerVJNdMeta?.name ?? "dashboard-items-my-assets-sniper-assets-SniperTableOperations___ru",
    path: SniperTableOperationsK62QerVJNdMeta?.path ?? "/ru/dashboard/items/my-assets/sniper-assets/SniperTableOperations",
    meta: SniperTableOperationsK62QerVJNdMeta || {},
    alias: SniperTableOperationsK62QerVJNdMeta?.alias || [],
    redirect: SniperTableOperationsK62QerVJNdMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/items/my-assets/sniper-assets/SniperTableOperations.vue").then(m => m.default || m)
  },
  {
    name: indexBcTFITARB0Meta?.name ?? "dashboard-items-my-assets-total-net-assets___en",
    path: indexBcTFITARB0Meta?.path ?? "/dashboard/items/my-assets/total-net-assets",
    meta: indexBcTFITARB0Meta || {},
    alias: indexBcTFITARB0Meta?.alias || [],
    redirect: indexBcTFITARB0Meta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/items/my-assets/total-net-assets/index.ts").then(m => m.default || m)
  },
  {
    name: indexBcTFITARB0Meta?.name ?? "dashboard-items-my-assets-total-net-assets___ru",
    path: indexBcTFITARB0Meta?.path ?? "/ru/dashboard/items/my-assets/total-net-assets",
    meta: indexBcTFITARB0Meta || {},
    alias: indexBcTFITARB0Meta?.alias || [],
    redirect: indexBcTFITARB0Meta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/items/my-assets/total-net-assets/index.ts").then(m => m.default || m)
  },
  {
    name: TNAHeaderoCWV2cLrRQMeta?.name ?? "dashboard-items-my-assets-total-net-assets-TNAHeader___en",
    path: TNAHeaderoCWV2cLrRQMeta?.path ?? "/dashboard/items/my-assets/total-net-assets/TNAHeader",
    meta: TNAHeaderoCWV2cLrRQMeta || {},
    alias: TNAHeaderoCWV2cLrRQMeta?.alias || [],
    redirect: TNAHeaderoCWV2cLrRQMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/items/my-assets/total-net-assets/TNAHeader.vue").then(m => m.default || m)
  },
  {
    name: TNAHeaderoCWV2cLrRQMeta?.name ?? "dashboard-items-my-assets-total-net-assets-TNAHeader___ru",
    path: TNAHeaderoCWV2cLrRQMeta?.path ?? "/ru/dashboard/items/my-assets/total-net-assets/TNAHeader",
    meta: TNAHeaderoCWV2cLrRQMeta || {},
    alias: TNAHeaderoCWV2cLrRQMeta?.alias || [],
    redirect: TNAHeaderoCWV2cLrRQMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/items/my-assets/total-net-assets/TNAHeader.vue").then(m => m.default || m)
  },
  {
    name: TotalNetAssetsEGRZHnNnL8Meta?.name ?? "dashboard-items-my-assets-total-net-assets-TotalNetAssets___en",
    path: TotalNetAssetsEGRZHnNnL8Meta?.path ?? "/dashboard/items/my-assets/total-net-assets/TotalNetAssets",
    meta: TotalNetAssetsEGRZHnNnL8Meta || {},
    alias: TotalNetAssetsEGRZHnNnL8Meta?.alias || [],
    redirect: TotalNetAssetsEGRZHnNnL8Meta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/items/my-assets/total-net-assets/TotalNetAssets.vue").then(m => m.default || m)
  },
  {
    name: TotalNetAssetsEGRZHnNnL8Meta?.name ?? "dashboard-items-my-assets-total-net-assets-TotalNetAssets___ru",
    path: TotalNetAssetsEGRZHnNnL8Meta?.path ?? "/ru/dashboard/items/my-assets/total-net-assets/TotalNetAssets",
    meta: TotalNetAssetsEGRZHnNnL8Meta || {},
    alias: TotalNetAssetsEGRZHnNnL8Meta?.alias || [],
    redirect: TotalNetAssetsEGRZHnNnL8Meta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/items/my-assets/total-net-assets/TotalNetAssets.vue").then(m => m.default || m)
  },
  {
    name: BulletAssetsrAOXwR5DOsMeta?.name ?? "dashboard-items-my-bullet-BulletAssets___en",
    path: BulletAssetsrAOXwR5DOsMeta?.path ?? "/dashboard/items/my-bullet/BulletAssets",
    meta: BulletAssetsrAOXwR5DOsMeta || {},
    alias: BulletAssetsrAOXwR5DOsMeta?.alias || [],
    redirect: BulletAssetsrAOXwR5DOsMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/items/my-bullet/BulletAssets.vue").then(m => m.default || m)
  },
  {
    name: BulletAssetsrAOXwR5DOsMeta?.name ?? "dashboard-items-my-bullet-BulletAssets___ru",
    path: BulletAssetsrAOXwR5DOsMeta?.path ?? "/ru/dashboard/items/my-bullet/BulletAssets",
    meta: BulletAssetsrAOXwR5DOsMeta || {},
    alias: BulletAssetsrAOXwR5DOsMeta?.alias || [],
    redirect: BulletAssetsrAOXwR5DOsMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/items/my-bullet/BulletAssets.vue").then(m => m.default || m)
  },
  {
    name: BulletStatusr1tdwL2EM1Meta?.name ?? "dashboard-items-my-bullet-BulletStatus___en",
    path: BulletStatusr1tdwL2EM1Meta?.path ?? "/dashboard/items/my-bullet/BulletStatus",
    meta: BulletStatusr1tdwL2EM1Meta || {},
    alias: BulletStatusr1tdwL2EM1Meta?.alias || [],
    redirect: BulletStatusr1tdwL2EM1Meta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/items/my-bullet/BulletStatus.vue").then(m => m.default || m)
  },
  {
    name: BulletStatusr1tdwL2EM1Meta?.name ?? "dashboard-items-my-bullet-BulletStatus___ru",
    path: BulletStatusr1tdwL2EM1Meta?.path ?? "/ru/dashboard/items/my-bullet/BulletStatus",
    meta: BulletStatusr1tdwL2EM1Meta || {},
    alias: BulletStatusr1tdwL2EM1Meta?.alias || [],
    redirect: BulletStatusr1tdwL2EM1Meta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/items/my-bullet/BulletStatus.vue").then(m => m.default || m)
  },
  {
    name: BulletTable3whTPnCD3jMeta?.name ?? "dashboard-items-my-bullet-BulletTable___en",
    path: BulletTable3whTPnCD3jMeta?.path ?? "/dashboard/items/my-bullet/BulletTable",
    meta: BulletTable3whTPnCD3jMeta || {},
    alias: BulletTable3whTPnCD3jMeta?.alias || [],
    redirect: BulletTable3whTPnCD3jMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/items/my-bullet/BulletTable.vue").then(m => m.default || m)
  },
  {
    name: BulletTable3whTPnCD3jMeta?.name ?? "dashboard-items-my-bullet-BulletTable___ru",
    path: BulletTable3whTPnCD3jMeta?.path ?? "/ru/dashboard/items/my-bullet/BulletTable",
    meta: BulletTable3whTPnCD3jMeta || {},
    alias: BulletTable3whTPnCD3jMeta?.alias || [],
    redirect: BulletTable3whTPnCD3jMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/items/my-bullet/BulletTable.vue").then(m => m.default || m)
  },
  {
    name: BulletTableOperations04rhK9qQLXMeta?.name ?? "dashboard-items-my-bullet-BulletTableOperations___en",
    path: BulletTableOperations04rhK9qQLXMeta?.path ?? "/dashboard/items/my-bullet/BulletTableOperations",
    meta: BulletTableOperations04rhK9qQLXMeta || {},
    alias: BulletTableOperations04rhK9qQLXMeta?.alias || [],
    redirect: BulletTableOperations04rhK9qQLXMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/items/my-bullet/BulletTableOperations.vue").then(m => m.default || m)
  },
  {
    name: BulletTableOperations04rhK9qQLXMeta?.name ?? "dashboard-items-my-bullet-BulletTableOperations___ru",
    path: BulletTableOperations04rhK9qQLXMeta?.path ?? "/ru/dashboard/items/my-bullet/BulletTableOperations",
    meta: BulletTableOperations04rhK9qQLXMeta || {},
    alias: BulletTableOperations04rhK9qQLXMeta?.alias || [],
    redirect: BulletTableOperations04rhK9qQLXMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/items/my-bullet/BulletTableOperations.vue").then(m => m.default || m)
  },
  {
    name: indexojQVjQfugwMeta?.name ?? "dashboard-items-my-bullet___en",
    path: indexojQVjQfugwMeta?.path ?? "/dashboard/items/my-bullet",
    meta: indexojQVjQfugwMeta || {},
    alias: indexojQVjQfugwMeta?.alias || [],
    redirect: indexojQVjQfugwMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/items/my-bullet/index.ts").then(m => m.default || m)
  },
  {
    name: indexojQVjQfugwMeta?.name ?? "dashboard-items-my-bullet___ru",
    path: indexojQVjQfugwMeta?.path ?? "/ru/dashboard/items/my-bullet",
    meta: indexojQVjQfugwMeta || {},
    alias: indexojQVjQfugwMeta?.alias || [],
    redirect: indexojQVjQfugwMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/items/my-bullet/index.ts").then(m => m.default || m)
  },
  {
    name: MyBullet9RsaBoQsGRMeta?.name ?? "dashboard-items-my-bullet-MyBullet___en",
    path: MyBullet9RsaBoQsGRMeta?.path ?? "/dashboard/items/my-bullet/MyBullet",
    meta: MyBullet9RsaBoQsGRMeta || {},
    alias: MyBullet9RsaBoQsGRMeta?.alias || [],
    redirect: MyBullet9RsaBoQsGRMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/items/my-bullet/MyBullet.vue").then(m => m.default || m)
  },
  {
    name: MyBullet9RsaBoQsGRMeta?.name ?? "dashboard-items-my-bullet-MyBullet___ru",
    path: MyBullet9RsaBoQsGRMeta?.path ?? "/ru/dashboard/items/my-bullet/MyBullet",
    meta: MyBullet9RsaBoQsGRMeta || {},
    alias: MyBullet9RsaBoQsGRMeta?.alias || [],
    redirect: MyBullet9RsaBoQsGRMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/items/my-bullet/MyBullet.vue").then(m => m.default || m)
  },
  {
    name: indexOMwe2AuZO5Meta?.name ?? "index___en",
    path: indexOMwe2AuZO5Meta?.path ?? "/",
    meta: indexOMwe2AuZO5Meta || {},
    alias: indexOMwe2AuZO5Meta?.alias || [],
    redirect: indexOMwe2AuZO5Meta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: indexOMwe2AuZO5Meta?.name ?? "index___ru",
    path: indexOMwe2AuZO5Meta?.path ?? "/ru",
    meta: indexOMwe2AuZO5Meta || {},
    alias: indexOMwe2AuZO5Meta?.alias || [],
    redirect: indexOMwe2AuZO5Meta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: indexYN6BSuswbjMeta?.name ?? "lock___en",
    path: indexYN6BSuswbjMeta?.path ?? "/lock",
    meta: indexYN6BSuswbjMeta || {},
    alias: indexYN6BSuswbjMeta?.alias || [],
    redirect: indexYN6BSuswbjMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/lock/index.vue").then(m => m.default || m)
  },
  {
    name: indexYN6BSuswbjMeta?.name ?? "lock___ru",
    path: indexYN6BSuswbjMeta?.path ?? "/ru/lock",
    meta: indexYN6BSuswbjMeta || {},
    alias: indexYN6BSuswbjMeta?.alias || [],
    redirect: indexYN6BSuswbjMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/lock/index.vue").then(m => m.default || m)
  },
  {
    name: AvailableSectionOKPJhEhwwkMeta?.name ?? "lock-items-available-section-AvailableSection___en",
    path: AvailableSectionOKPJhEhwwkMeta?.path ?? "/lock/items/available-section/AvailableSection",
    meta: AvailableSectionOKPJhEhwwkMeta || {},
    alias: AvailableSectionOKPJhEhwwkMeta?.alias || [],
    redirect: AvailableSectionOKPJhEhwwkMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/lock/items/available-section/AvailableSection.vue").then(m => m.default || m)
  },
  {
    name: AvailableSectionOKPJhEhwwkMeta?.name ?? "lock-items-available-section-AvailableSection___ru",
    path: AvailableSectionOKPJhEhwwkMeta?.path ?? "/ru/lock/items/available-section/AvailableSection",
    meta: AvailableSectionOKPJhEhwwkMeta || {},
    alias: AvailableSectionOKPJhEhwwkMeta?.alias || [],
    redirect: AvailableSectionOKPJhEhwwkMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/lock/items/available-section/AvailableSection.vue").then(m => m.default || m)
  },
  {
    name: AvailableSectionHeaderSGejVf0MroMeta?.name ?? "lock-items-available-section-AvailableSectionHeader___en",
    path: AvailableSectionHeaderSGejVf0MroMeta?.path ?? "/lock/items/available-section/AvailableSectionHeader",
    meta: AvailableSectionHeaderSGejVf0MroMeta || {},
    alias: AvailableSectionHeaderSGejVf0MroMeta?.alias || [],
    redirect: AvailableSectionHeaderSGejVf0MroMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/lock/items/available-section/AvailableSectionHeader.vue").then(m => m.default || m)
  },
  {
    name: AvailableSectionHeaderSGejVf0MroMeta?.name ?? "lock-items-available-section-AvailableSectionHeader___ru",
    path: AvailableSectionHeaderSGejVf0MroMeta?.path ?? "/ru/lock/items/available-section/AvailableSectionHeader",
    meta: AvailableSectionHeaderSGejVf0MroMeta || {},
    alias: AvailableSectionHeaderSGejVf0MroMeta?.alias || [],
    redirect: AvailableSectionHeaderSGejVf0MroMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/lock/items/available-section/AvailableSectionHeader.vue").then(m => m.default || m)
  },
  {
    name: BulletListDialogcJYpo9nyXzMeta?.name ?? "lock-items-available-section-bullet-list-dialog-BulletListDialog___en",
    path: BulletListDialogcJYpo9nyXzMeta?.path ?? "/lock/items/available-section/bullet-list-dialog/BulletListDialog",
    meta: BulletListDialogcJYpo9nyXzMeta || {},
    alias: BulletListDialogcJYpo9nyXzMeta?.alias || [],
    redirect: BulletListDialogcJYpo9nyXzMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/lock/items/available-section/bullet-list-dialog/BulletListDialog.vue").then(m => m.default || m)
  },
  {
    name: BulletListDialogcJYpo9nyXzMeta?.name ?? "lock-items-available-section-bullet-list-dialog-BulletListDialog___ru",
    path: BulletListDialogcJYpo9nyXzMeta?.path ?? "/ru/lock/items/available-section/bullet-list-dialog/BulletListDialog",
    meta: BulletListDialogcJYpo9nyXzMeta || {},
    alias: BulletListDialogcJYpo9nyXzMeta?.alias || [],
    redirect: BulletListDialogcJYpo9nyXzMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/lock/items/available-section/bullet-list-dialog/BulletListDialog.vue").then(m => m.default || m)
  },
  {
    name: indexSxHN0hkeGwMeta?.name ?? "lock-items-available-section-bullet-list-dialog___en",
    path: indexSxHN0hkeGwMeta?.path ?? "/lock/items/available-section/bullet-list-dialog",
    meta: indexSxHN0hkeGwMeta || {},
    alias: indexSxHN0hkeGwMeta?.alias || [],
    redirect: indexSxHN0hkeGwMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/lock/items/available-section/bullet-list-dialog/index.ts").then(m => m.default || m)
  },
  {
    name: indexSxHN0hkeGwMeta?.name ?? "lock-items-available-section-bullet-list-dialog___ru",
    path: indexSxHN0hkeGwMeta?.path ?? "/ru/lock/items/available-section/bullet-list-dialog",
    meta: indexSxHN0hkeGwMeta || {},
    alias: indexSxHN0hkeGwMeta?.alias || [],
    redirect: indexSxHN0hkeGwMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/lock/items/available-section/bullet-list-dialog/index.ts").then(m => m.default || m)
  },
  {
    name: BulletRaffleInfoWo57hITfPcMeta?.name ?? "lock-items-available-section-bullet-raffle-info-BulletRaffleInfo___en",
    path: BulletRaffleInfoWo57hITfPcMeta?.path ?? "/lock/items/available-section/bullet-raffle-info/BulletRaffleInfo",
    meta: BulletRaffleInfoWo57hITfPcMeta || {},
    alias: BulletRaffleInfoWo57hITfPcMeta?.alias || [],
    redirect: BulletRaffleInfoWo57hITfPcMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/lock/items/available-section/bullet-raffle-info/BulletRaffleInfo.vue").then(m => m.default || m)
  },
  {
    name: BulletRaffleInfoWo57hITfPcMeta?.name ?? "lock-items-available-section-bullet-raffle-info-BulletRaffleInfo___ru",
    path: BulletRaffleInfoWo57hITfPcMeta?.path ?? "/ru/lock/items/available-section/bullet-raffle-info/BulletRaffleInfo",
    meta: BulletRaffleInfoWo57hITfPcMeta || {},
    alias: BulletRaffleInfoWo57hITfPcMeta?.alias || [],
    redirect: BulletRaffleInfoWo57hITfPcMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/lock/items/available-section/bullet-raffle-info/BulletRaffleInfo.vue").then(m => m.default || m)
  },
  {
    name: BulletRaffleInfoHeaderEGwfcfIEATMeta?.name ?? "lock-items-available-section-bullet-raffle-info-BulletRaffleInfoHeader___en",
    path: BulletRaffleInfoHeaderEGwfcfIEATMeta?.path ?? "/lock/items/available-section/bullet-raffle-info/BulletRaffleInfoHeader",
    meta: BulletRaffleInfoHeaderEGwfcfIEATMeta || {},
    alias: BulletRaffleInfoHeaderEGwfcfIEATMeta?.alias || [],
    redirect: BulletRaffleInfoHeaderEGwfcfIEATMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/lock/items/available-section/bullet-raffle-info/BulletRaffleInfoHeader.vue").then(m => m.default || m)
  },
  {
    name: BulletRaffleInfoHeaderEGwfcfIEATMeta?.name ?? "lock-items-available-section-bullet-raffle-info-BulletRaffleInfoHeader___ru",
    path: BulletRaffleInfoHeaderEGwfcfIEATMeta?.path ?? "/ru/lock/items/available-section/bullet-raffle-info/BulletRaffleInfoHeader",
    meta: BulletRaffleInfoHeaderEGwfcfIEATMeta || {},
    alias: BulletRaffleInfoHeaderEGwfcfIEATMeta?.alias || [],
    redirect: BulletRaffleInfoHeaderEGwfcfIEATMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/lock/items/available-section/bullet-raffle-info/BulletRaffleInfoHeader.vue").then(m => m.default || m)
  },
  {
    name: BulletRaffleInfoItemGn8q8KRGkgMeta?.name ?? "lock-items-available-section-bullet-raffle-info-BulletRaffleInfoItem___en",
    path: BulletRaffleInfoItemGn8q8KRGkgMeta?.path ?? "/lock/items/available-section/bullet-raffle-info/BulletRaffleInfoItem",
    meta: BulletRaffleInfoItemGn8q8KRGkgMeta || {},
    alias: BulletRaffleInfoItemGn8q8KRGkgMeta?.alias || [],
    redirect: BulletRaffleInfoItemGn8q8KRGkgMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/lock/items/available-section/bullet-raffle-info/BulletRaffleInfoItem.vue").then(m => m.default || m)
  },
  {
    name: BulletRaffleInfoItemGn8q8KRGkgMeta?.name ?? "lock-items-available-section-bullet-raffle-info-BulletRaffleInfoItem___ru",
    path: BulletRaffleInfoItemGn8q8KRGkgMeta?.path ?? "/ru/lock/items/available-section/bullet-raffle-info/BulletRaffleInfoItem",
    meta: BulletRaffleInfoItemGn8q8KRGkgMeta || {},
    alias: BulletRaffleInfoItemGn8q8KRGkgMeta?.alias || [],
    redirect: BulletRaffleInfoItemGn8q8KRGkgMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/lock/items/available-section/bullet-raffle-info/BulletRaffleInfoItem.vue").then(m => m.default || m)
  },
  {
    name: BulletRaffleInfoItemsHyzOVk5XuLMeta?.name ?? "lock-items-available-section-bullet-raffle-info-BulletRaffleInfoItems___en",
    path: BulletRaffleInfoItemsHyzOVk5XuLMeta?.path ?? "/lock/items/available-section/bullet-raffle-info/BulletRaffleInfoItems",
    meta: BulletRaffleInfoItemsHyzOVk5XuLMeta || {},
    alias: BulletRaffleInfoItemsHyzOVk5XuLMeta?.alias || [],
    redirect: BulletRaffleInfoItemsHyzOVk5XuLMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/lock/items/available-section/bullet-raffle-info/BulletRaffleInfoItems.vue").then(m => m.default || m)
  },
  {
    name: BulletRaffleInfoItemsHyzOVk5XuLMeta?.name ?? "lock-items-available-section-bullet-raffle-info-BulletRaffleInfoItems___ru",
    path: BulletRaffleInfoItemsHyzOVk5XuLMeta?.path ?? "/ru/lock/items/available-section/bullet-raffle-info/BulletRaffleInfoItems",
    meta: BulletRaffleInfoItemsHyzOVk5XuLMeta || {},
    alias: BulletRaffleInfoItemsHyzOVk5XuLMeta?.alias || [],
    redirect: BulletRaffleInfoItemsHyzOVk5XuLMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/lock/items/available-section/bullet-raffle-info/BulletRaffleInfoItems.vue").then(m => m.default || m)
  },
  {
    name: indexUykrjtUlL0Meta?.name ?? "lock-items-available-section-bullet-raffle-info___en",
    path: indexUykrjtUlL0Meta?.path ?? "/lock/items/available-section/bullet-raffle-info",
    meta: indexUykrjtUlL0Meta || {},
    alias: indexUykrjtUlL0Meta?.alias || [],
    redirect: indexUykrjtUlL0Meta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/lock/items/available-section/bullet-raffle-info/index.ts").then(m => m.default || m)
  },
  {
    name: indexUykrjtUlL0Meta?.name ?? "lock-items-available-section-bullet-raffle-info___ru",
    path: indexUykrjtUlL0Meta?.path ?? "/ru/lock/items/available-section/bullet-raffle-info",
    meta: indexUykrjtUlL0Meta || {},
    alias: indexUykrjtUlL0Meta?.alias || [],
    redirect: indexUykrjtUlL0Meta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/lock/items/available-section/bullet-raffle-info/index.ts").then(m => m.default || m)
  },
  {
    name: indexlXTOyrCDxlMeta?.name ?? "lock-items-available-section___en",
    path: indexlXTOyrCDxlMeta?.path ?? "/lock/items/available-section",
    meta: indexlXTOyrCDxlMeta || {},
    alias: indexlXTOyrCDxlMeta?.alias || [],
    redirect: indexlXTOyrCDxlMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/lock/items/available-section/index.ts").then(m => m.default || m)
  },
  {
    name: indexlXTOyrCDxlMeta?.name ?? "lock-items-available-section___ru",
    path: indexlXTOyrCDxlMeta?.path ?? "/ru/lock/items/available-section",
    meta: indexlXTOyrCDxlMeta || {},
    alias: indexlXTOyrCDxlMeta?.alias || [],
    redirect: indexlXTOyrCDxlMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/lock/items/available-section/index.ts").then(m => m.default || m)
  },
  {
    name: index6L17NXFseYMeta?.name ?? "lock-items-lock-status-section___en",
    path: index6L17NXFseYMeta?.path ?? "/lock/items/lock-status-section",
    meta: index6L17NXFseYMeta || {},
    alias: index6L17NXFseYMeta?.alias || [],
    redirect: index6L17NXFseYMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/lock/items/lock-status-section/index.ts").then(m => m.default || m)
  },
  {
    name: index6L17NXFseYMeta?.name ?? "lock-items-lock-status-section___ru",
    path: index6L17NXFseYMeta?.path ?? "/ru/lock/items/lock-status-section",
    meta: index6L17NXFseYMeta || {},
    alias: index6L17NXFseYMeta?.alias || [],
    redirect: index6L17NXFseYMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/lock/items/lock-status-section/index.ts").then(m => m.default || m)
  },
  {
    name: BulletRewardRowj5WEhKJiX6Meta?.name ?? "lock-items-lock-status-section-lock-simulator-BulletRewardRow___en",
    path: BulletRewardRowj5WEhKJiX6Meta?.path ?? "/lock/items/lock-status-section/lock-simulator/BulletRewardRow",
    meta: BulletRewardRowj5WEhKJiX6Meta || {},
    alias: BulletRewardRowj5WEhKJiX6Meta?.alias || [],
    redirect: BulletRewardRowj5WEhKJiX6Meta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/lock/items/lock-status-section/lock-simulator/BulletRewardRow.vue").then(m => m.default || m)
  },
  {
    name: BulletRewardRowj5WEhKJiX6Meta?.name ?? "lock-items-lock-status-section-lock-simulator-BulletRewardRow___ru",
    path: BulletRewardRowj5WEhKJiX6Meta?.path ?? "/ru/lock/items/lock-status-section/lock-simulator/BulletRewardRow",
    meta: BulletRewardRowj5WEhKJiX6Meta || {},
    alias: BulletRewardRowj5WEhKJiX6Meta?.alias || [],
    redirect: BulletRewardRowj5WEhKJiX6Meta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/lock/items/lock-status-section/lock-simulator/BulletRewardRow.vue").then(m => m.default || m)
  },
  {
    name: indexRSiOiN2O3kMeta?.name ?? "lock-items-lock-status-section-lock-simulator___en",
    path: indexRSiOiN2O3kMeta?.path ?? "/lock/items/lock-status-section/lock-simulator",
    meta: indexRSiOiN2O3kMeta || {},
    alias: indexRSiOiN2O3kMeta?.alias || [],
    redirect: indexRSiOiN2O3kMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/lock/items/lock-status-section/lock-simulator/index.ts").then(m => m.default || m)
  },
  {
    name: indexRSiOiN2O3kMeta?.name ?? "lock-items-lock-status-section-lock-simulator___ru",
    path: indexRSiOiN2O3kMeta?.path ?? "/ru/lock/items/lock-status-section/lock-simulator",
    meta: indexRSiOiN2O3kMeta || {},
    alias: indexRSiOiN2O3kMeta?.alias || [],
    redirect: indexRSiOiN2O3kMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/lock/items/lock-status-section/lock-simulator/index.ts").then(m => m.default || m)
  },
  {
    name: LockSimulatorlyTqEewAE6Meta?.name ?? "lock-items-lock-status-section-lock-simulator-LockSimulator___en",
    path: LockSimulatorlyTqEewAE6Meta?.path ?? "/lock/items/lock-status-section/lock-simulator/LockSimulator",
    meta: LockSimulatorlyTqEewAE6Meta || {},
    alias: LockSimulatorlyTqEewAE6Meta?.alias || [],
    redirect: LockSimulatorlyTqEewAE6Meta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/lock/items/lock-status-section/lock-simulator/LockSimulator.vue").then(m => m.default || m)
  },
  {
    name: LockSimulatorlyTqEewAE6Meta?.name ?? "lock-items-lock-status-section-lock-simulator-LockSimulator___ru",
    path: LockSimulatorlyTqEewAE6Meta?.path ?? "/ru/lock/items/lock-status-section/lock-simulator/LockSimulator",
    meta: LockSimulatorlyTqEewAE6Meta || {},
    alias: LockSimulatorlyTqEewAE6Meta?.alias || [],
    redirect: LockSimulatorlyTqEewAE6Meta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/lock/items/lock-status-section/lock-simulator/LockSimulator.vue").then(m => m.default || m)
  },
  {
    name: NewSharingRow1t8w0BRtO1Meta?.name ?? "lock-items-lock-status-section-lock-simulator-NewSharingRow___en",
    path: NewSharingRow1t8w0BRtO1Meta?.path ?? "/lock/items/lock-status-section/lock-simulator/NewSharingRow",
    meta: NewSharingRow1t8w0BRtO1Meta || {},
    alias: NewSharingRow1t8w0BRtO1Meta?.alias || [],
    redirect: NewSharingRow1t8w0BRtO1Meta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/lock/items/lock-status-section/lock-simulator/NewSharingRow.vue").then(m => m.default || m)
  },
  {
    name: NewSharingRow1t8w0BRtO1Meta?.name ?? "lock-items-lock-status-section-lock-simulator-NewSharingRow___ru",
    path: NewSharingRow1t8w0BRtO1Meta?.path ?? "/ru/lock/items/lock-status-section/lock-simulator/NewSharingRow",
    meta: NewSharingRow1t8w0BRtO1Meta || {},
    alias: NewSharingRow1t8w0BRtO1Meta?.alias || [],
    redirect: NewSharingRow1t8w0BRtO1Meta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/lock/items/lock-status-section/lock-simulator/NewSharingRow.vue").then(m => m.default || m)
  },
  {
    name: TodaysSharingRow1K7xRqdHWUMeta?.name ?? "lock-items-lock-status-section-lock-simulator-TodaysSharingRow___en",
    path: TodaysSharingRow1K7xRqdHWUMeta?.path ?? "/lock/items/lock-status-section/lock-simulator/TodaysSharingRow",
    meta: TodaysSharingRow1K7xRqdHWUMeta || {},
    alias: TodaysSharingRow1K7xRqdHWUMeta?.alias || [],
    redirect: TodaysSharingRow1K7xRqdHWUMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/lock/items/lock-status-section/lock-simulator/TodaysSharingRow.vue").then(m => m.default || m)
  },
  {
    name: TodaysSharingRow1K7xRqdHWUMeta?.name ?? "lock-items-lock-status-section-lock-simulator-TodaysSharingRow___ru",
    path: TodaysSharingRow1K7xRqdHWUMeta?.path ?? "/ru/lock/items/lock-status-section/lock-simulator/TodaysSharingRow",
    meta: TodaysSharingRow1K7xRqdHWUMeta || {},
    alias: TodaysSharingRow1K7xRqdHWUMeta?.alias || [],
    redirect: TodaysSharingRow1K7xRqdHWUMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/lock/items/lock-status-section/lock-simulator/TodaysSharingRow.vue").then(m => m.default || m)
  },
  {
    name: TotalLockAmountRowikj2S7GBlbMeta?.name ?? "lock-items-lock-status-section-lock-simulator-TotalLockAmountRow___en",
    path: TotalLockAmountRowikj2S7GBlbMeta?.path ?? "/lock/items/lock-status-section/lock-simulator/TotalLockAmountRow",
    meta: TotalLockAmountRowikj2S7GBlbMeta || {},
    alias: TotalLockAmountRowikj2S7GBlbMeta?.alias || [],
    redirect: TotalLockAmountRowikj2S7GBlbMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/lock/items/lock-status-section/lock-simulator/TotalLockAmountRow.vue").then(m => m.default || m)
  },
  {
    name: TotalLockAmountRowikj2S7GBlbMeta?.name ?? "lock-items-lock-status-section-lock-simulator-TotalLockAmountRow___ru",
    path: TotalLockAmountRowikj2S7GBlbMeta?.path ?? "/ru/lock/items/lock-status-section/lock-simulator/TotalLockAmountRow",
    meta: TotalLockAmountRowikj2S7GBlbMeta || {},
    alias: TotalLockAmountRowikj2S7GBlbMeta?.alias || [],
    redirect: TotalLockAmountRowikj2S7GBlbMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/lock/items/lock-status-section/lock-simulator/TotalLockAmountRow.vue").then(m => m.default || m)
  },
  {
    name: indext1S1qH4K3hMeta?.name ?? "lock-items-lock-status-section-locked-info___en",
    path: indext1S1qH4K3hMeta?.path ?? "/lock/items/lock-status-section/locked-info",
    meta: indext1S1qH4K3hMeta || {},
    alias: indext1S1qH4K3hMeta?.alias || [],
    redirect: indext1S1qH4K3hMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/lock/items/lock-status-section/locked-info/index.ts").then(m => m.default || m)
  },
  {
    name: indext1S1qH4K3hMeta?.name ?? "lock-items-lock-status-section-locked-info___ru",
    path: indext1S1qH4K3hMeta?.path ?? "/ru/lock/items/lock-status-section/locked-info",
    meta: indext1S1qH4K3hMeta || {},
    alias: indext1S1qH4K3hMeta?.alias || [],
    redirect: indext1S1qH4K3hMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/lock/items/lock-status-section/locked-info/index.ts").then(m => m.default || m)
  },
  {
    name: LockedInfoXf66gD6a74Meta?.name ?? "lock-items-lock-status-section-locked-info-LockedInfo___en",
    path: LockedInfoXf66gD6a74Meta?.path ?? "/lock/items/lock-status-section/locked-info/LockedInfo",
    meta: LockedInfoXf66gD6a74Meta || {},
    alias: LockedInfoXf66gD6a74Meta?.alias || [],
    redirect: LockedInfoXf66gD6a74Meta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/lock/items/lock-status-section/locked-info/LockedInfo.vue").then(m => m.default || m)
  },
  {
    name: LockedInfoXf66gD6a74Meta?.name ?? "lock-items-lock-status-section-locked-info-LockedInfo___ru",
    path: LockedInfoXf66gD6a74Meta?.path ?? "/ru/lock/items/lock-status-section/locked-info/LockedInfo",
    meta: LockedInfoXf66gD6a74Meta || {},
    alias: LockedInfoXf66gD6a74Meta?.alias || [],
    redirect: LockedInfoXf66gD6a74Meta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/lock/items/lock-status-section/locked-info/LockedInfo.vue").then(m => m.default || m)
  },
  {
    name: LockedInfoChartJvX6Ui6xyqMeta?.name ?? "lock-items-lock-status-section-locked-info-LockedInfoChart___en",
    path: LockedInfoChartJvX6Ui6xyqMeta?.path ?? "/lock/items/lock-status-section/locked-info/LockedInfoChart",
    meta: LockedInfoChartJvX6Ui6xyqMeta || {},
    alias: LockedInfoChartJvX6Ui6xyqMeta?.alias || [],
    redirect: LockedInfoChartJvX6Ui6xyqMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/lock/items/lock-status-section/locked-info/LockedInfoChart.vue").then(m => m.default || m)
  },
  {
    name: LockedInfoChartJvX6Ui6xyqMeta?.name ?? "lock-items-lock-status-section-locked-info-LockedInfoChart___ru",
    path: LockedInfoChartJvX6Ui6xyqMeta?.path ?? "/ru/lock/items/lock-status-section/locked-info/LockedInfoChart",
    meta: LockedInfoChartJvX6Ui6xyqMeta || {},
    alias: LockedInfoChartJvX6Ui6xyqMeta?.alias || [],
    redirect: LockedInfoChartJvX6Ui6xyqMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/lock/items/lock-status-section/locked-info/LockedInfoChart.vue").then(m => m.default || m)
  },
  {
    name: LockedInfoHeaderewta5ITW77Meta?.name ?? "lock-items-lock-status-section-locked-info-LockedInfoHeader___en",
    path: LockedInfoHeaderewta5ITW77Meta?.path ?? "/lock/items/lock-status-section/locked-info/LockedInfoHeader",
    meta: LockedInfoHeaderewta5ITW77Meta || {},
    alias: LockedInfoHeaderewta5ITW77Meta?.alias || [],
    redirect: LockedInfoHeaderewta5ITW77Meta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/lock/items/lock-status-section/locked-info/LockedInfoHeader.vue").then(m => m.default || m)
  },
  {
    name: LockedInfoHeaderewta5ITW77Meta?.name ?? "lock-items-lock-status-section-locked-info-LockedInfoHeader___ru",
    path: LockedInfoHeaderewta5ITW77Meta?.path ?? "/ru/lock/items/lock-status-section/locked-info/LockedInfoHeader",
    meta: LockedInfoHeaderewta5ITW77Meta || {},
    alias: LockedInfoHeaderewta5ITW77Meta?.alias || [],
    redirect: LockedInfoHeaderewta5ITW77Meta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/lock/items/lock-status-section/locked-info/LockedInfoHeader.vue").then(m => m.default || m)
  },
  {
    name: LockedInfoStatJh7o8cc1RjMeta?.name ?? "lock-items-lock-status-section-locked-info-LockedInfoStat___en",
    path: LockedInfoStatJh7o8cc1RjMeta?.path ?? "/lock/items/lock-status-section/locked-info/LockedInfoStat",
    meta: LockedInfoStatJh7o8cc1RjMeta || {},
    alias: LockedInfoStatJh7o8cc1RjMeta?.alias || [],
    redirect: LockedInfoStatJh7o8cc1RjMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/lock/items/lock-status-section/locked-info/LockedInfoStat.vue").then(m => m.default || m)
  },
  {
    name: LockedInfoStatJh7o8cc1RjMeta?.name ?? "lock-items-lock-status-section-locked-info-LockedInfoStat___ru",
    path: LockedInfoStatJh7o8cc1RjMeta?.path ?? "/ru/lock/items/lock-status-section/locked-info/LockedInfoStat",
    meta: LockedInfoStatJh7o8cc1RjMeta || {},
    alias: LockedInfoStatJh7o8cc1RjMeta?.alias || [],
    redirect: LockedInfoStatJh7o8cc1RjMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/lock/items/lock-status-section/locked-info/LockedInfoStat.vue").then(m => m.default || m)
  },
  {
    name: LockedInfoStatsveJnRxb9nEMeta?.name ?? "lock-items-lock-status-section-locked-info-LockedInfoStats___en",
    path: LockedInfoStatsveJnRxb9nEMeta?.path ?? "/lock/items/lock-status-section/locked-info/LockedInfoStats",
    meta: LockedInfoStatsveJnRxb9nEMeta || {},
    alias: LockedInfoStatsveJnRxb9nEMeta?.alias || [],
    redirect: LockedInfoStatsveJnRxb9nEMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/lock/items/lock-status-section/locked-info/LockedInfoStats.vue").then(m => m.default || m)
  },
  {
    name: LockedInfoStatsveJnRxb9nEMeta?.name ?? "lock-items-lock-status-section-locked-info-LockedInfoStats___ru",
    path: LockedInfoStatsveJnRxb9nEMeta?.path ?? "/ru/lock/items/lock-status-section/locked-info/LockedInfoStats",
    meta: LockedInfoStatsveJnRxb9nEMeta || {},
    alias: LockedInfoStatsveJnRxb9nEMeta?.alias || [],
    redirect: LockedInfoStatsveJnRxb9nEMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/lock/items/lock-status-section/locked-info/LockedInfoStats.vue").then(m => m.default || m)
  },
  {
    name: LockStatusSectionjQJp4PL0JqMeta?.name ?? "lock-items-lock-status-section-LockStatusSection___en",
    path: LockStatusSectionjQJp4PL0JqMeta?.path ?? "/lock/items/lock-status-section/LockStatusSection",
    meta: LockStatusSectionjQJp4PL0JqMeta || {},
    alias: LockStatusSectionjQJp4PL0JqMeta?.alias || [],
    redirect: LockStatusSectionjQJp4PL0JqMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/lock/items/lock-status-section/LockStatusSection.vue").then(m => m.default || m)
  },
  {
    name: LockStatusSectionjQJp4PL0JqMeta?.name ?? "lock-items-lock-status-section-LockStatusSection___ru",
    path: LockStatusSectionjQJp4PL0JqMeta?.path ?? "/ru/lock/items/lock-status-section/LockStatusSection",
    meta: LockStatusSectionjQJp4PL0JqMeta || {},
    alias: LockStatusSectionjQJp4PL0JqMeta?.alias || [],
    redirect: LockStatusSectionjQJp4PL0JqMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/lock/items/lock-status-section/LockStatusSection.vue").then(m => m.default || m)
  },
  {
    name: BulletRewardsHnE0Miaw0FMeta?.name ?? "lock-items-rewards-section-bullet-rewards-BulletRewards___en",
    path: BulletRewardsHnE0Miaw0FMeta?.path ?? "/lock/items/rewards-section/bullet-rewards/BulletRewards",
    meta: BulletRewardsHnE0Miaw0FMeta || {},
    alias: BulletRewardsHnE0Miaw0FMeta?.alias || [],
    redirect: BulletRewardsHnE0Miaw0FMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/lock/items/rewards-section/bullet-rewards/BulletRewards.vue").then(m => m.default || m)
  },
  {
    name: BulletRewardsHnE0Miaw0FMeta?.name ?? "lock-items-rewards-section-bullet-rewards-BulletRewards___ru",
    path: BulletRewardsHnE0Miaw0FMeta?.path ?? "/ru/lock/items/rewards-section/bullet-rewards/BulletRewards",
    meta: BulletRewardsHnE0Miaw0FMeta || {},
    alias: BulletRewardsHnE0Miaw0FMeta?.alias || [],
    redirect: BulletRewardsHnE0Miaw0FMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/lock/items/rewards-section/bullet-rewards/BulletRewards.vue").then(m => m.default || m)
  },
  {
    name: BulletRewardsInfo5ekN0ABrX3Meta?.name ?? "lock-items-rewards-section-bullet-rewards-BulletRewardsInfo___en",
    path: BulletRewardsInfo5ekN0ABrX3Meta?.path ?? "/lock/items/rewards-section/bullet-rewards/BulletRewardsInfo",
    meta: BulletRewardsInfo5ekN0ABrX3Meta || {},
    alias: BulletRewardsInfo5ekN0ABrX3Meta?.alias || [],
    redirect: BulletRewardsInfo5ekN0ABrX3Meta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/lock/items/rewards-section/bullet-rewards/BulletRewardsInfo.vue").then(m => m.default || m)
  },
  {
    name: BulletRewardsInfo5ekN0ABrX3Meta?.name ?? "lock-items-rewards-section-bullet-rewards-BulletRewardsInfo___ru",
    path: BulletRewardsInfo5ekN0ABrX3Meta?.path ?? "/ru/lock/items/rewards-section/bullet-rewards/BulletRewardsInfo",
    meta: BulletRewardsInfo5ekN0ABrX3Meta || {},
    alias: BulletRewardsInfo5ekN0ABrX3Meta?.alias || [],
    redirect: BulletRewardsInfo5ekN0ABrX3Meta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/lock/items/rewards-section/bullet-rewards/BulletRewardsInfo.vue").then(m => m.default || m)
  },
  {
    name: indexAEWx2FlufEMeta?.name ?? "lock-items-rewards-section-bullet-rewards___en",
    path: indexAEWx2FlufEMeta?.path ?? "/lock/items/rewards-section/bullet-rewards",
    meta: indexAEWx2FlufEMeta || {},
    alias: indexAEWx2FlufEMeta?.alias || [],
    redirect: indexAEWx2FlufEMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/lock/items/rewards-section/bullet-rewards/index.ts").then(m => m.default || m)
  },
  {
    name: indexAEWx2FlufEMeta?.name ?? "lock-items-rewards-section-bullet-rewards___ru",
    path: indexAEWx2FlufEMeta?.path ?? "/ru/lock/items/rewards-section/bullet-rewards",
    meta: indexAEWx2FlufEMeta || {},
    alias: indexAEWx2FlufEMeta?.alias || [],
    redirect: indexAEWx2FlufEMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/lock/items/rewards-section/bullet-rewards/index.ts").then(m => m.default || m)
  },
  {
    name: indextxEZTRoRruMeta?.name ?? "lock-items-rewards-section___en",
    path: indextxEZTRoRruMeta?.path ?? "/lock/items/rewards-section",
    meta: indextxEZTRoRruMeta || {},
    alias: indextxEZTRoRruMeta?.alias || [],
    redirect: indextxEZTRoRruMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/lock/items/rewards-section/index.ts").then(m => m.default || m)
  },
  {
    name: indextxEZTRoRruMeta?.name ?? "lock-items-rewards-section___ru",
    path: indextxEZTRoRruMeta?.path ?? "/ru/lock/items/rewards-section",
    meta: indextxEZTRoRruMeta || {},
    alias: indextxEZTRoRruMeta?.alias || [],
    redirect: indextxEZTRoRruMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/lock/items/rewards-section/index.ts").then(m => m.default || m)
  },
  {
    name: LockingRewardsYoB9TJgxDlMeta?.name ?? "lock-items-rewards-section-locking-rewards-LockingRewards___en",
    path: LockingRewardsYoB9TJgxDlMeta?.path ?? "/lock/items/rewards-section/locking-rewards/LockingRewards",
    meta: LockingRewardsYoB9TJgxDlMeta || {},
    alias: LockingRewardsYoB9TJgxDlMeta?.alias || [],
    redirect: LockingRewardsYoB9TJgxDlMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/lock/items/rewards-section/locking-rewards/LockingRewards.vue").then(m => m.default || m)
  },
  {
    name: LockingRewardsYoB9TJgxDlMeta?.name ?? "lock-items-rewards-section-locking-rewards-LockingRewards___ru",
    path: LockingRewardsYoB9TJgxDlMeta?.path ?? "/ru/lock/items/rewards-section/locking-rewards/LockingRewards",
    meta: LockingRewardsYoB9TJgxDlMeta || {},
    alias: LockingRewardsYoB9TJgxDlMeta?.alias || [],
    redirect: LockingRewardsYoB9TJgxDlMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/lock/items/rewards-section/locking-rewards/LockingRewards.vue").then(m => m.default || m)
  },
  {
    name: LockingRewardsInfoUUQ0FRsurjMeta?.name ?? "lock-items-rewards-section-locking-rewards-LockingRewardsInfo___en",
    path: LockingRewardsInfoUUQ0FRsurjMeta?.path ?? "/lock/items/rewards-section/locking-rewards/LockingRewardsInfo",
    meta: LockingRewardsInfoUUQ0FRsurjMeta || {},
    alias: LockingRewardsInfoUUQ0FRsurjMeta?.alias || [],
    redirect: LockingRewardsInfoUUQ0FRsurjMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/lock/items/rewards-section/locking-rewards/LockingRewardsInfo.vue").then(m => m.default || m)
  },
  {
    name: LockingRewardsInfoUUQ0FRsurjMeta?.name ?? "lock-items-rewards-section-locking-rewards-LockingRewardsInfo___ru",
    path: LockingRewardsInfoUUQ0FRsurjMeta?.path ?? "/ru/lock/items/rewards-section/locking-rewards/LockingRewardsInfo",
    meta: LockingRewardsInfoUUQ0FRsurjMeta || {},
    alias: LockingRewardsInfoUUQ0FRsurjMeta?.alias || [],
    redirect: LockingRewardsInfoUUQ0FRsurjMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/lock/items/rewards-section/locking-rewards/LockingRewardsInfo.vue").then(m => m.default || m)
  },
  {
    name: TokenRewardItemIQh0sFXPRCMeta?.name ?? "lock-items-rewards-section-locking-rewards-TokenRewardItem___en",
    path: TokenRewardItemIQh0sFXPRCMeta?.path ?? "/lock/items/rewards-section/locking-rewards/TokenRewardItem",
    meta: TokenRewardItemIQh0sFXPRCMeta || {},
    alias: TokenRewardItemIQh0sFXPRCMeta?.alias || [],
    redirect: TokenRewardItemIQh0sFXPRCMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/lock/items/rewards-section/locking-rewards/TokenRewardItem.vue").then(m => m.default || m)
  },
  {
    name: TokenRewardItemIQh0sFXPRCMeta?.name ?? "lock-items-rewards-section-locking-rewards-TokenRewardItem___ru",
    path: TokenRewardItemIQh0sFXPRCMeta?.path ?? "/ru/lock/items/rewards-section/locking-rewards/TokenRewardItem",
    meta: TokenRewardItemIQh0sFXPRCMeta || {},
    alias: TokenRewardItemIQh0sFXPRCMeta?.alias || [],
    redirect: TokenRewardItemIQh0sFXPRCMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/lock/items/rewards-section/locking-rewards/TokenRewardItem.vue").then(m => m.default || m)
  },
  {
    name: TokenRewards6SzrIRsggvMeta?.name ?? "lock-items-rewards-section-locking-rewards-TokenRewards___en",
    path: TokenRewards6SzrIRsggvMeta?.path ?? "/lock/items/rewards-section/locking-rewards/TokenRewards",
    meta: TokenRewards6SzrIRsggvMeta || {},
    alias: TokenRewards6SzrIRsggvMeta?.alias || [],
    redirect: TokenRewards6SzrIRsggvMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/lock/items/rewards-section/locking-rewards/TokenRewards.vue").then(m => m.default || m)
  },
  {
    name: TokenRewards6SzrIRsggvMeta?.name ?? "lock-items-rewards-section-locking-rewards-TokenRewards___ru",
    path: TokenRewards6SzrIRsggvMeta?.path ?? "/ru/lock/items/rewards-section/locking-rewards/TokenRewards",
    meta: TokenRewards6SzrIRsggvMeta || {},
    alias: TokenRewards6SzrIRsggvMeta?.alias || [],
    redirect: TokenRewards6SzrIRsggvMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/lock/items/rewards-section/locking-rewards/TokenRewards.vue").then(m => m.default || m)
  },
  {
    name: RewardsSectionK3esdi1OyoMeta?.name ?? "lock-items-rewards-section-RewardsSection___en",
    path: RewardsSectionK3esdi1OyoMeta?.path ?? "/lock/items/rewards-section/RewardsSection",
    meta: RewardsSectionK3esdi1OyoMeta || {},
    alias: RewardsSectionK3esdi1OyoMeta?.alias || [],
    redirect: RewardsSectionK3esdi1OyoMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/lock/items/rewards-section/RewardsSection.vue").then(m => m.default || m)
  },
  {
    name: RewardsSectionK3esdi1OyoMeta?.name ?? "lock-items-rewards-section-RewardsSection___ru",
    path: RewardsSectionK3esdi1OyoMeta?.path ?? "/ru/lock/items/rewards-section/RewardsSection",
    meta: RewardsSectionK3esdi1OyoMeta || {},
    alias: RewardsSectionK3esdi1OyoMeta?.alias || [],
    redirect: RewardsSectionK3esdi1OyoMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/lock/items/rewards-section/RewardsSection.vue").then(m => m.default || m)
  },
  {
    name: indexB1p6RFr3OPMeta?.name ?? "lock-items-stats-section___en",
    path: indexB1p6RFr3OPMeta?.path ?? "/lock/items/stats-section",
    meta: indexB1p6RFr3OPMeta || {},
    alias: indexB1p6RFr3OPMeta?.alias || [],
    redirect: indexB1p6RFr3OPMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/lock/items/stats-section/index.ts").then(m => m.default || m)
  },
  {
    name: indexB1p6RFr3OPMeta?.name ?? "lock-items-stats-section___ru",
    path: indexB1p6RFr3OPMeta?.path ?? "/ru/lock/items/stats-section",
    meta: indexB1p6RFr3OPMeta || {},
    alias: indexB1p6RFr3OPMeta?.alias || [],
    redirect: indexB1p6RFr3OPMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/lock/items/stats-section/index.ts").then(m => m.default || m)
  },
  {
    name: StatsSectionHCqjPmJif5Meta?.name ?? "lock-items-stats-section-StatsSection___en",
    path: StatsSectionHCqjPmJif5Meta?.path ?? "/lock/items/stats-section/StatsSection",
    meta: StatsSectionHCqjPmJif5Meta || {},
    alias: StatsSectionHCqjPmJif5Meta?.alias || [],
    redirect: StatsSectionHCqjPmJif5Meta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/lock/items/stats-section/StatsSection.vue").then(m => m.default || m)
  },
  {
    name: StatsSectionHCqjPmJif5Meta?.name ?? "lock-items-stats-section-StatsSection___ru",
    path: StatsSectionHCqjPmJif5Meta?.path ?? "/ru/lock/items/stats-section/StatsSection",
    meta: StatsSectionHCqjPmJif5Meta || {},
    alias: StatsSectionHCqjPmJif5Meta?.alias || [],
    redirect: StatsSectionHCqjPmJif5Meta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/lock/items/stats-section/StatsSection.vue").then(m => m.default || m)
  },
  {
    name: indexB871uAzj5nMeta?.name ?? "pools___en",
    path: indexB871uAzj5nMeta?.path ?? "/pools",
    meta: indexB871uAzj5nMeta || {},
    alias: indexB871uAzj5nMeta?.alias || [],
    redirect: indexB871uAzj5nMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/pools/index.ts").then(m => m.default || m)
  },
  {
    name: indexB871uAzj5nMeta?.name ?? "pools___ru",
    path: indexB871uAzj5nMeta?.path ?? "/ru/pools",
    meta: indexB871uAzj5nMeta || {},
    alias: indexB871uAzj5nMeta?.alias || [],
    redirect: indexB871uAzj5nMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/pools/index.ts").then(m => m.default || m)
  },
  {
    name: indexV32RJAuD0XMeta?.name ?? "pools-items-my-pools___en",
    path: indexV32RJAuD0XMeta?.path ?? "/pools/items/my-pools",
    meta: indexV32RJAuD0XMeta || {},
    alias: indexV32RJAuD0XMeta?.alias || [],
    redirect: indexV32RJAuD0XMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/pools/items/my-pools/index.ts").then(m => m.default || m)
  },
  {
    name: indexV32RJAuD0XMeta?.name ?? "pools-items-my-pools___ru",
    path: indexV32RJAuD0XMeta?.path ?? "/ru/pools/items/my-pools",
    meta: indexV32RJAuD0XMeta || {},
    alias: indexV32RJAuD0XMeta?.alias || [],
    redirect: indexV32RJAuD0XMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/pools/items/my-pools/index.ts").then(m => m.default || m)
  },
  {
    name: MyPoolsry8nz5FoZ9Meta?.name ?? "pools-items-my-pools-MyPools___en",
    path: MyPoolsry8nz5FoZ9Meta?.path ?? "/pools/items/my-pools/MyPools",
    meta: MyPoolsry8nz5FoZ9Meta || {},
    alias: MyPoolsry8nz5FoZ9Meta?.alias || [],
    redirect: MyPoolsry8nz5FoZ9Meta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/pools/items/my-pools/MyPools.vue").then(m => m.default || m)
  },
  {
    name: MyPoolsry8nz5FoZ9Meta?.name ?? "pools-items-my-pools-MyPools___ru",
    path: MyPoolsry8nz5FoZ9Meta?.path ?? "/ru/pools/items/my-pools/MyPools",
    meta: MyPoolsry8nz5FoZ9Meta || {},
    alias: MyPoolsry8nz5FoZ9Meta?.alias || [],
    redirect: MyPoolsry8nz5FoZ9Meta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/pools/items/my-pools/MyPools.vue").then(m => m.default || m)
  },
  {
    name: PoolItemvX1Qi8xAVYMeta?.name ?? "pools-items-my-pools-PoolItem___en",
    path: PoolItemvX1Qi8xAVYMeta?.path ?? "/pools/items/my-pools/PoolItem",
    meta: PoolItemvX1Qi8xAVYMeta || {},
    alias: PoolItemvX1Qi8xAVYMeta?.alias || [],
    redirect: PoolItemvX1Qi8xAVYMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/pools/items/my-pools/PoolItem.vue").then(m => m.default || m)
  },
  {
    name: PoolItemvX1Qi8xAVYMeta?.name ?? "pools-items-my-pools-PoolItem___ru",
    path: PoolItemvX1Qi8xAVYMeta?.path ?? "/ru/pools/items/my-pools/PoolItem",
    meta: PoolItemvX1Qi8xAVYMeta || {},
    alias: PoolItemvX1Qi8xAVYMeta?.alias || [],
    redirect: PoolItemvX1Qi8xAVYMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/pools/items/my-pools/PoolItem.vue").then(m => m.default || m)
  },
  {
    name: PoolsEHyqAhsO2aMeta?.name ?? "pools-items-my-pools-Pools___en",
    path: PoolsEHyqAhsO2aMeta?.path ?? "/pools/items/my-pools/Pools",
    meta: PoolsEHyqAhsO2aMeta || {},
    alias: PoolsEHyqAhsO2aMeta?.alias || [],
    redirect: PoolsEHyqAhsO2aMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/pools/items/my-pools/Pools.vue").then(m => m.default || m)
  },
  {
    name: PoolsEHyqAhsO2aMeta?.name ?? "pools-items-my-pools-Pools___ru",
    path: PoolsEHyqAhsO2aMeta?.path ?? "/ru/pools/items/my-pools/Pools",
    meta: PoolsEHyqAhsO2aMeta || {},
    alias: PoolsEHyqAhsO2aMeta?.alias || [],
    redirect: PoolsEHyqAhsO2aMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/pools/items/my-pools/Pools.vue").then(m => m.default || m)
  },
  {
    name: PoolsGridFDFXSDIVffMeta?.name ?? "pools-items-my-pools-PoolsGrid___en",
    path: PoolsGridFDFXSDIVffMeta?.path ?? "/pools/items/my-pools/PoolsGrid",
    meta: PoolsGridFDFXSDIVffMeta || {},
    alias: PoolsGridFDFXSDIVffMeta?.alias || [],
    redirect: PoolsGridFDFXSDIVffMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/pools/items/my-pools/PoolsGrid.vue").then(m => m.default || m)
  },
  {
    name: PoolsGridFDFXSDIVffMeta?.name ?? "pools-items-my-pools-PoolsGrid___ru",
    path: PoolsGridFDFXSDIVffMeta?.path ?? "/ru/pools/items/my-pools/PoolsGrid",
    meta: PoolsGridFDFXSDIVffMeta || {},
    alias: PoolsGridFDFXSDIVffMeta?.alias || [],
    redirect: PoolsGridFDFXSDIVffMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/pools/items/my-pools/PoolsGrid.vue").then(m => m.default || m)
  },
  {
    name: index6bVjbFgi2HMeta?.name ?? "pools-items-pool-section-header___en",
    path: index6bVjbFgi2HMeta?.path ?? "/pools/items/pool-section-header",
    meta: index6bVjbFgi2HMeta || {},
    alias: index6bVjbFgi2HMeta?.alias || [],
    redirect: index6bVjbFgi2HMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/pools/items/pool-section-header/index.ts").then(m => m.default || m)
  },
  {
    name: index6bVjbFgi2HMeta?.name ?? "pools-items-pool-section-header___ru",
    path: index6bVjbFgi2HMeta?.path ?? "/ru/pools/items/pool-section-header",
    meta: index6bVjbFgi2HMeta || {},
    alias: index6bVjbFgi2HMeta?.alias || [],
    redirect: index6bVjbFgi2HMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/pools/items/pool-section-header/index.ts").then(m => m.default || m)
  },
  {
    name: PoolFilterAndSortByWvgh7JAncTMeta?.name ?? "pools-items-pool-section-header-PoolFilterAndSortBy___en",
    path: PoolFilterAndSortByWvgh7JAncTMeta?.path ?? "/pools/items/pool-section-header/PoolFilterAndSortBy",
    meta: PoolFilterAndSortByWvgh7JAncTMeta || {},
    alias: PoolFilterAndSortByWvgh7JAncTMeta?.alias || [],
    redirect: PoolFilterAndSortByWvgh7JAncTMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/pools/items/pool-section-header/PoolFilterAndSortBy.vue").then(m => m.default || m)
  },
  {
    name: PoolFilterAndSortByWvgh7JAncTMeta?.name ?? "pools-items-pool-section-header-PoolFilterAndSortBy___ru",
    path: PoolFilterAndSortByWvgh7JAncTMeta?.path ?? "/ru/pools/items/pool-section-header/PoolFilterAndSortBy",
    meta: PoolFilterAndSortByWvgh7JAncTMeta || {},
    alias: PoolFilterAndSortByWvgh7JAncTMeta?.alias || [],
    redirect: PoolFilterAndSortByWvgh7JAncTMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/pools/items/pool-section-header/PoolFilterAndSortBy.vue").then(m => m.default || m)
  },
  {
    name: PoolSectionHeader3UX1FVQFBfMeta?.name ?? "pools-items-pool-section-header-PoolSectionHeader___en",
    path: PoolSectionHeader3UX1FVQFBfMeta?.path ?? "/pools/items/pool-section-header/PoolSectionHeader",
    meta: PoolSectionHeader3UX1FVQFBfMeta || {},
    alias: PoolSectionHeader3UX1FVQFBfMeta?.alias || [],
    redirect: PoolSectionHeader3UX1FVQFBfMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/pools/items/pool-section-header/PoolSectionHeader.vue").then(m => m.default || m)
  },
  {
    name: PoolSectionHeader3UX1FVQFBfMeta?.name ?? "pools-items-pool-section-header-PoolSectionHeader___ru",
    path: PoolSectionHeader3UX1FVQFBfMeta?.path ?? "/ru/pools/items/pool-section-header/PoolSectionHeader",
    meta: PoolSectionHeader3UX1FVQFBfMeta || {},
    alias: PoolSectionHeader3UX1FVQFBfMeta?.alias || [],
    redirect: PoolSectionHeader3UX1FVQFBfMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/pools/items/pool-section-header/PoolSectionHeader.vue").then(m => m.default || m)
  },
  {
    name: indexLb3SqFmXm4Meta?.name ?? "pools-items-staking-pools___en",
    path: indexLb3SqFmXm4Meta?.path ?? "/pools/items/staking-pools",
    meta: indexLb3SqFmXm4Meta || {},
    alias: indexLb3SqFmXm4Meta?.alias || [],
    redirect: indexLb3SqFmXm4Meta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/pools/items/staking-pools/index.ts").then(m => m.default || m)
  },
  {
    name: indexLb3SqFmXm4Meta?.name ?? "pools-items-staking-pools___ru",
    path: indexLb3SqFmXm4Meta?.path ?? "/ru/pools/items/staking-pools",
    meta: indexLb3SqFmXm4Meta || {},
    alias: indexLb3SqFmXm4Meta?.alias || [],
    redirect: indexLb3SqFmXm4Meta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/pools/items/staking-pools/index.ts").then(m => m.default || m)
  },
  {
    name: PoolItemvK5d3mAQNYMeta?.name ?? "pools-items-staking-pools-PoolItem___en",
    path: PoolItemvK5d3mAQNYMeta?.path ?? "/pools/items/staking-pools/PoolItem",
    meta: PoolItemvK5d3mAQNYMeta || {},
    alias: PoolItemvK5d3mAQNYMeta?.alias || [],
    redirect: PoolItemvK5d3mAQNYMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/pools/items/staking-pools/PoolItem.vue").then(m => m.default || m)
  },
  {
    name: PoolItemvK5d3mAQNYMeta?.name ?? "pools-items-staking-pools-PoolItem___ru",
    path: PoolItemvK5d3mAQNYMeta?.path ?? "/ru/pools/items/staking-pools/PoolItem",
    meta: PoolItemvK5d3mAQNYMeta || {},
    alias: PoolItemvK5d3mAQNYMeta?.alias || [],
    redirect: PoolItemvK5d3mAQNYMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/pools/items/staking-pools/PoolItem.vue").then(m => m.default || m)
  },
  {
    name: PoolsScqq0STlijMeta?.name ?? "pools-items-staking-pools-Pools___en",
    path: PoolsScqq0STlijMeta?.path ?? "/pools/items/staking-pools/Pools",
    meta: PoolsScqq0STlijMeta || {},
    alias: PoolsScqq0STlijMeta?.alias || [],
    redirect: PoolsScqq0STlijMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/pools/items/staking-pools/Pools.vue").then(m => m.default || m)
  },
  {
    name: PoolsScqq0STlijMeta?.name ?? "pools-items-staking-pools-Pools___ru",
    path: PoolsScqq0STlijMeta?.path ?? "/ru/pools/items/staking-pools/Pools",
    meta: PoolsScqq0STlijMeta || {},
    alias: PoolsScqq0STlijMeta?.alias || [],
    redirect: PoolsScqq0STlijMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/pools/items/staking-pools/Pools.vue").then(m => m.default || m)
  },
  {
    name: PoolsGridehPciAuJy1Meta?.name ?? "pools-items-staking-pools-PoolsGrid___en",
    path: PoolsGridehPciAuJy1Meta?.path ?? "/pools/items/staking-pools/PoolsGrid",
    meta: PoolsGridehPciAuJy1Meta || {},
    alias: PoolsGridehPciAuJy1Meta?.alias || [],
    redirect: PoolsGridehPciAuJy1Meta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/pools/items/staking-pools/PoolsGrid.vue").then(m => m.default || m)
  },
  {
    name: PoolsGridehPciAuJy1Meta?.name ?? "pools-items-staking-pools-PoolsGrid___ru",
    path: PoolsGridehPciAuJy1Meta?.path ?? "/ru/pools/items/staking-pools/PoolsGrid",
    meta: PoolsGridehPciAuJy1Meta || {},
    alias: PoolsGridehPciAuJy1Meta?.alias || [],
    redirect: PoolsGridehPciAuJy1Meta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/pools/items/staking-pools/PoolsGrid.vue").then(m => m.default || m)
  },
  {
    name: StakingPoolsbp24ukCJIfMeta?.name ?? "pools-items-staking-pools-StakingPools___en",
    path: StakingPoolsbp24ukCJIfMeta?.path ?? "/pools/items/staking-pools/StakingPools",
    meta: StakingPoolsbp24ukCJIfMeta || {},
    alias: StakingPoolsbp24ukCJIfMeta?.alias || [],
    redirect: StakingPoolsbp24ukCJIfMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/pools/items/staking-pools/StakingPools.vue").then(m => m.default || m)
  },
  {
    name: StakingPoolsbp24ukCJIfMeta?.name ?? "pools-items-staking-pools-StakingPools___ru",
    path: StakingPoolsbp24ukCJIfMeta?.path ?? "/ru/pools/items/staking-pools/StakingPools",
    meta: StakingPoolsbp24ukCJIfMeta || {},
    alias: StakingPoolsbp24ukCJIfMeta?.alias || [],
    redirect: StakingPoolsbp24ukCJIfMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/pools/items/staking-pools/StakingPools.vue").then(m => m.default || m)
  },
  {
    name: PoolsKZpAvvQd9EMeta?.name ?? "pools-Pools___en",
    path: PoolsKZpAvvQd9EMeta?.path ?? "/pools/Pools",
    meta: PoolsKZpAvvQd9EMeta || {},
    alias: PoolsKZpAvvQd9EMeta?.alias || [],
    redirect: PoolsKZpAvvQd9EMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/pools/Pools.vue").then(m => m.default || m)
  },
  {
    name: PoolsKZpAvvQd9EMeta?.name ?? "pools-Pools___ru",
    path: PoolsKZpAvvQd9EMeta?.path ?? "/ru/pools/Pools",
    meta: PoolsKZpAvvQd9EMeta || {},
    alias: PoolsKZpAvvQd9EMeta?.alias || [],
    redirect: PoolsKZpAvvQd9EMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/pools/Pools.vue").then(m => m.default || m)
  }
]