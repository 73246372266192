import routerOptions0 from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.14.10_bufferutil@4.0.8_eslint@8.57.0_ioredis_45efhutvcb6uktx66gc26ae3qe/node_modules/nuxt/dist/pages/runtime/router.options";
import routerOptions1 from "/vercel/path0/packages/base/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}