<template>
  <component
    :is="!!component ? component : COMPONENT_MAP[props.variant]"
    v-bind="{
      ...$attrs,
    }"
    :class="[
      'ui-text',
      `ui-text--${props.variant}`,
      `ui-text--${props.color}`,
      { 'ui-text--centered': !!props.centered },
    ]"
  >
    <slot name="default"></slot>
  </component>
</template>
<script lang="ts" setup>
import type { Component, PropType } from 'vue'

export type TextVariant =
  | 'display'
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'body1'
  | 'body2'
  | 'body3'
  | 'body4'
  | 'body5'
  | 'button1'
  | 'button2'
  | 'button3'
  | 'textButton0'
  | 'textButton1'
  | 'textButton2'

export type TextColor =
  | 'primary'
  | 'secondary'
  | 'textPrimary'
  | 'textSecondary'
  | 'textTertiary'
  | 'sell'
  | 'buy'
  | 'tag'
  | 'error'
  | 'success'
  | 'warning'
  | 'e-hodl'
  | 'inherit'
  | 'highlight'
  | 'lightGray'

const props = defineProps({
  /**
   * The variant of the text
   * @default body1
   */
  variant: {
    type: String as PropType<TextVariant>,
    default: 'body1',
  },
  /**
   * The color of the text
   * @default textPrimary
   */
  color: {
    type: String as PropType<TextColor>,
    default: 'textPrimary',
  },
  /**
   * Whether the text should be centered.
   * @default false
   */
  centered: {
    type: Boolean,
    default: false,
  },
  /**
   * The component to render the text as.
   * If not provided, the component will be determined by the variant.
   * @default ''
   */
  component: {
    type: String as PropType<string | Component>,
    default: '',
  },
})

/**
 * The map of text variants to their corresponding components.
 */
const COMPONENT_MAP: Record<TextVariant, string> = {
  display: 'h1',
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  body1: 'span',
  body2: 'span',
  body3: 'span',
  body4: 'span',
  body5: 'span',
  button1: 'span',
  button2: 'span',
  button3: 'span',
  textButton0: 'span',
  textButton1: 'span',
  textButton2: 'span',
} as const
</script>
<style lang="postcss" scoped>
.ui-text {
  color: var(--brand-color-text-primary);
}
.ui-text.ui-text--primary {
  color: var(--brand-color-primary);
}
.ui-text.ui-text--secondary {
  color: var(--brand-color-secondary);
}
.ui-text.ui-text--textPrimary {
  color: var(--brand-color-text-primary);
}
.ui-text.ui-text--textSecondary {
  color: var(--brand-color-text-secondary);
}
.ui-text.ui-text--textTertiary {
  color: var(--brand-color-text-tertiary);
}
.ui-text.ui-text--sell {
  color: var(--brand-color-sell);
}
.ui-text.ui-text--buy {
  color: var(--brand-color-buy);
}
.ui-text.ui-text--tag {
  color: var(--brand-color-tag);
}
.ui-text.ui-text--error {
  color: var(--brand-color-error);
}
.ui-text.ui-text--success {
  color: var(--brand-color-success);
}
.ui-text.ui-text--warning {
  color: var(--brand-color-warning);
}
.ui-text.ui-text--e-hodl {
  color: var(--brand-color-e-hodl);
}
.ui-text.ui-text--inherit {
  color: inherit;
}
.ui-text.ui-text--highlight {
  color: var(--brand-color-primary-focus);
}
.ui-text.ui-text--centered {
  text-align: center;
}
.ui-text.ui-text--display {
  font-family: var(--font-family-secondary);
  font-size: 3rem; /* 48px */
  font-weight: 600;
  line-height: 4.125rem; /* 66px */
}
.ui-text.ui-text--h1 {
  font-family: var(--font-family-secondary);
  font-size: 2.25rem; /* 36px */
  font-weight: 600;
  line-height: 2.875rem; /* 46px */
}
.ui-text.ui-text--h2 {
  font-family: var(--font-family-secondary);
  font-size: 1.75rem; /* 28px */
  font-weight: 600;
  line-height: 2.25rem; /* 36px */
}
.ui-text.ui-text--h3 {
  font-family: var(--font-family-secondary);
  font-size: 1.5rem; /* 24px */
  font-weight: 600;
  line-height: 2rem; /* 32px */
}
.ui-text.ui-text--h4 {
  font-family: var(--font-family-secondary);
  font-size: 1.375rem; /* 22px */
  font-weight: 600;
  line-height: 1.75rem; /* 28px */
}
.ui-text.ui-text--h5 {
  font-family: var(--font-family-secondary);
  font-size: 1.125rem; /* 18px */
  font-weight: 600;
  line-height: 1.5rem; /* 24px */
}
.ui-text.ui-text--h6 {
  font-family: var(--font-family-secondary);
  font-size: 1rem; /* 16px */
  font-weight: 600;
  line-height: 1.375rem; /* 22px */
}
.ui-text.ui-text--body1 {
  font-family: var(--font-family-primary);
  font-size: 1.125rem; /* 18px */
  font-weight: 400;
  line-height: 1.5rem; /* 24px */
}
.ui-text.ui-text--body2 {
  font-family: var(--font-family-primary);
  font-size: 1rem; /* 16px */
  font-weight: 600;
  line-height: 1.375rem; /* 22px */
}
.ui-text.ui-text--body3 {
  font-family: var(--font-family-primary);
  font-size: 0.875rem; /* 14px */
  font-weight: 400;
  line-height: 1.125rem; /* 18px */
}
.ui-text.ui-text--body4 {
  font-family: var(--font-family-primary);
  font-size: 0.75rem; /* 12px */
  font-weight: 400;
  line-height: 1rem; /* 16px */
}
.ui-text.ui-text--body5 {
  font-family: var(--font-family-primary);
  font-size: 0.75rem; /* 12px */
  font-weight: 300;
  line-height: 1rem; /* 16px */
}
.ui-text.ui-text--button1 {
  font-family: var(--font-family-secondary);
  font-size: 1rem; /* 16px */
  font-weight: 600;
  line-height: 1.375rem; /* 22px */
}
.ui-text.ui-text--button2 {
  font-family: var(--font-family-secondary);
  font-size: 0.875rem; /* 14px */
  font-weight: 600;
  line-height: 1.125rem; /* 18px */
}
.ui-text.ui-text--button3 {
  font-family: var(--font-family-secondary);
  font-size: 0.75rem; /* 12px */
  font-weight: 600;
  line-height: 1rem; /* 16px */
}
.ui-text.ui-text--textButton0 {
  font-family: var(--font-family-primary);
  font-size: 1rem; /* 16px */
  font-weight: 400;
  line-height: 1.375rem; /* 22px */
}
.ui-text.ui-text--textButton1 {
  font-family: var(--font-family-primary);
  font-size: 0.875rem; /* 14px */
  font-weight: 400;
  line-height: 1.125rem; /* 18px */
}
.ui-text.ui-text--textButton2 {
  font-family: var(--font-family-primary);
  font-size: 0.75rem; /* 12px */
  font-weight: 400;
  line-height: 1rem; /* 16px */
}
</style>
