import Pools from './Pools.vue'
import type { PoolStatus, SortValue } from '@base/types/sniperPools'

export type PoolPageSection = 'stakingPools' | 'myPools'
export type ShowPoolPageSection = PoolPageSection | undefined
export interface EmittedFilters {
  poolTypeSelected: PoolStatus
  poolSortSelected: SortValue
}

export default Pools
