import * as Sentry from '@sentry/browser'
import { CaptureConsole } from '@sentry/integrations'

export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig().public

  if (config.NODE_ENV === 'production' && config.IS_ON_VERCEL) {
    Sentry.init({
      environment: config.VERCEL_ENV === 'preview' ? 'Preview' : 'Production',
      dsn: config.NUXT_SENTRY_DSN as string,
      integrations: [
        new Sentry.BrowserTracing(),
        new CaptureConsole({ levels: ['error'] }),
      ],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      tracesSampleRate: 1.0,
      beforeSend(event) {
        if (event.message && event.message.includes('Page not found')) {
          return null
        }
        return event
      },
    })
  }
})
