export type ScreenSizeType = 'sm' | 'md' | 'lg'
/**
 * Get the current screen size based on the window width.
 * @returns {string} The screen size ('sm', 'md', or 'lg').
 */
export function useScreenSize() {
  const { width } = useWindowSize()
  const size = computed(() => {
    let _size: ScreenSizeType | null = null
    if (width.value < 768) {
      _size = 'sm'
    } else if (width.value < 1280) {
      _size = 'md'
    } else {
      _size = 'lg'
    }
    return _size
  })

  const isMobileScreen = computed(() => size.value === 'sm')
  const isTabletScreen = computed(() => size.value === 'md')
  const isDesktopScreen = computed(() => size.value === 'lg')

  return {
    size,
    isMobileScreen,
    isTabletScreen,
    isDesktopScreen,
  }
}
