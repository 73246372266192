<template>
  <component :is="component" class="ui-flex" :class="styles.classNames">
    <slot />
  </component>
</template>
<script setup lang="ts">
import type {
  ResponsiveSetting,
  CSSPropertiesAlignItems,
  CSSPropertiesJustifyContent,
  CSSPropertiesFlexWrap,
  CSSPropertiesFlexDirection,
} from '@base/types/ui'
const props = defineProps({
  fullWidth: {
    type: [Boolean, Object] as PropType<
      boolean | ResponsiveSetting | undefined
    >,
    default: false,
  },
  gap: {
    type: [Number, String, Object] as PropType<
      number | string | ResponsiveSetting | undefined
    >,
    default: undefined,
  },
  rowGap: {
    type: [Number, String, Object] as PropType<
      number | string | ResponsiveSetting | undefined
    >,
    default: undefined,
  },
  columnGap: {
    type: [Number, String, Object] as PropType<
      number | string | ResponsiveSetting | undefined
    >,
    default: undefined,
  },
  align: {
    type: [String, Object] as PropType<
      CSSPropertiesAlignItems | ResponsiveSetting | undefined
    >,
    default: undefined,
  },
  justify: {
    type: [String, Object] as PropType<
      CSSPropertiesJustifyContent | ResponsiveSetting | undefined
    >,
    default: undefined,
  },
  wrap: {
    type: [String, Object] as PropType<
      CSSPropertiesFlexWrap | ResponsiveSetting | undefined
    >,
    default: undefined,
  },
  direction: {
    type: [String, Object] as PropType<
      CSSPropertiesFlexDirection | ResponsiveSetting | undefined
    >,
    default: undefined,
  },
  component: {
    type: String,
    default: 'div',
  },
})

const { size: screenSize } = useScreenSize()

const toRem = (px: number | string | boolean) => {
  if (typeof px === 'string' && px === undefined) return '0rem'
  if (typeof px === 'boolean') return '0rem'
  else return `${+px / 16}rem`
}

const responsiveProp = (
  propValue: string | number | boolean | ResponsiveSetting,
) => {
  if (typeof propValue === 'object') {
    return propValue[screenSize.value]
  }
  return propValue
}

interface IProp {
  name: keyof typeof props
  value: boolean | number | string | ResponsiveSetting | undefined
  default: boolean | string
}

const getClassNamesAndValues = (propsArr: Array<IProp>) => {
  const classNames = propsArr
    .filter((prop) => {
      if (prop.default === false && prop.value == false) return false // filter out props like fullWidth
      return true
    })
    .flatMap((prop) => `ui-${prop.name}`)
    .join(' ')

  let values: Partial<Record<keyof typeof props, string>> = {}
  propsArr.forEach((prop) => {
    if (['gap', 'rowGap', 'columnGap'].includes(prop.name)) {
      const gapValue = responsiveProp(
        propsArr.find((prop) => prop.name === 'gap')?.value ?? '0',
      )
      values = {
        ...values,
        [prop.name]: toRem(responsiveProp(prop.value ?? gapValue)),
      }
    } else {
      values = {
        ...values,
        [prop.name]: responsiveProp(prop.value ?? prop.default),
      }
    }
  })

  return {
    classNames,
    values,
  }
}

const styles = computed(() => {
  const { fullWidth, align, justify, wrap, direction, gap, rowGap, columnGap } =
    props
  const propsArr: Array<IProp> = [
    { name: 'fullWidth', value: fullWidth, default: false },
    { name: 'align', value: align, default: 'stretch' },
    { name: 'justify', value: justify, default: 'flex-start' },
    { name: 'wrap', value: wrap, default: 'nowrap' },
    { name: 'direction', value: direction, default: 'row' },
    { name: 'gap', value: gap, default: '0' },
    { name: 'rowGap', value: rowGap, default: '0' },
    { name: 'columnGap', value: columnGap, default: '0' },
  ]

  return getClassNamesAndValues(propsArr)
})
</script>
<style lang="postcss" scoped>
.ui-flex {
  display: flex;
  &.ui-fullWidth {
    width: 100%;
  }

  &.ui-direction {
    flex-direction: v-bind(styles.values.direction);
  }

  &.ui-justify {
    justify-content: v-bind(styles.values.justify);
  }

  &.ui-align {
    align-items: v-bind(styles.values.align);
  }

  &.ui-wrap {
    flex-wrap: v-bind(styles.values.wrap);
  }
  &.ui-gap {
    gap: v-bind(styles.values.gap);
  }
  &.ui-rowGap {
    row-gap: v-bind(styles.values.rowGap);
  }
  &.ui-columnGap {
    /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
    column-gap: v-bind(styles.values.columnGap);
  }
}
</style>
