import revive_payload_client_ghE8QPAWen from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.14.10_bufferutil@4.0.8_eslint@8.57.0_ioredis_45efhutvcb6uktx66gc26ae3qe/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_LhvQCNouu4 from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.14.10_bufferutil@4.0.8_eslint@8.57.0_ioredis_45efhutvcb6uktx66gc26ae3qe/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_zFxifYtlmd from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.14.10_bufferutil@4.0.8_eslint@8.57.0_ioredis_45efhutvcb6uktx66gc26ae3qe/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_cFGd9nCSqf from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.14.10_bufferutil@4.0.8_eslint@8.57.0_ioredis_45efhutvcb6uktx66gc26ae3qe/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_fIxSAfaQHZ from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.14.10_bufferutil@4.0.8_eslint@8.57.0_ioredis_45efhutvcb6uktx66gc26ae3qe/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_wtQNa9kJUL from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.5.1_magicast@0.3.4_rollup@4.18.1_typescript@5.4.5_vue@3.4.31_typescript@5.4.5_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/vercel/path0/apps/main/.nuxt/components.plugin.mjs";
import prefetch_client_hRkuyD2LKP from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.14.10_bufferutil@4.0.8_eslint@8.57.0_ioredis_45efhutvcb6uktx66gc26ae3qe/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import i18n_OfSH4GNLJL from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@8.3.1_magicast@0.3.4_rollup@4.18.1_vue@3.4.31_typescript@5.4.5_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_7ggiIWLhUg from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.14.10_bufferutil@4.0.8_eslint@8.57.0_ioredis_45efhutvcb6uktx66gc26ae3qe/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import vue3_apexcharts_client_fr2iIXXogu from "/vercel/path0/packages/base/extends/vue3-apexcharts/plugins/vue3-apexcharts.client.ts";
import vue_gtag_client_cc2n676v9b from "/vercel/path0/packages/base/extends/vue-gtag-next/plugins/vue-gtag.client.ts";
import globalError_lcYoWLDc9m from "/vercel/path0/packages/base/extends/sentry/plugins/globalError.ts";
import index_kLWevXN06L from "/vercel/path0/packages/base/extends/sentry/plugins/index.ts";
export default [
  revive_payload_client_ghE8QPAWen,
  unhead_LhvQCNouu4,
  router_zFxifYtlmd,
  payload_client_cFGd9nCSqf,
  check_outdated_build_client_fIxSAfaQHZ,
  plugin_vue3_wtQNa9kJUL,
  components_plugin_KR1HBZs4kY,
  prefetch_client_hRkuyD2LKP,
  i18n_OfSH4GNLJL,
  chunk_reload_client_7ggiIWLhUg,
  vue3_apexcharts_client_fr2iIXXogu,
  vue_gtag_client_cc2n676v9b,
  globalError_lcYoWLDc9m,
  index_kLWevXN06L
]