<template>
  <el-button
    v-bind="{ ...$attrs, ...props }"
    :class="[
      'ui-button',
      `ui-button--color-${color}`,
      {
        'ui-button--fullwidth': !!fullWidth,
        'ui-button--responsive': !!responsive,
        'ui-button--underline': !!underline,
      },
    ]"
  >
    <template #default>
      <slot name="default"></slot>
    </template>
  </el-button>
</template>
<script setup lang="ts">
import { buttonProps } from 'element-plus'
export type ButtonColor = 'primary' | 'secondary'

const props = defineProps({
  ...buttonProps,
  /**
   * Whether the button should be round. Overrides default "round" prop of el-button with true as default.
   * @default true
   */
  round: {
    type: Boolean,
    default: true,
  },
  /**
   * The color of the button (primary, secondary).
   * @default primary
   */
  color: {
    type: String as PropType<ButtonColor>,
    default: 'primary',
  },
  /**
   * Whether the button should take up the full width of its container.
   * @default false
   */
  fullWidth: {
    type: Boolean,
    default: false,
  },
  /**
   * Whether the button should be responsive to the screen size (changing font-size, padding, etc.).
   * If false, the button will not change its size based on the screen size, e.g. large button will always be large.
   * @default true
   */
  responsive: {
    type: Boolean,
    default: true,
  },
  /**
   * Whether the button text should have an underline.
   */
  underline: {
    type: Boolean,
    default: false,
  },
})
</script>
<style scoped lang="postcss">
.ui-button.el-button {
  --ui-button-large-padding: 1rem 1.75rem; /* 16px 28px */
  --ui-button-large-font-size: 1rem; /* 16px */
  --ui-button-large-font-weight: 600;
  --ui-button-large-line-height: 1.375rem; /* 22px */
  --ui-button-large-border-radius: 1.75rem; /* 28px */

  --ui-button-medium-padding: 1rem 1.5rem; /* 16px 24px */
  --ui-button-medium-font-size: 0.875rem; /* 14px */
  --ui-button-medium-font-weight: 600;
  --ui-button-medium-line-height: 1.125rem; /* 18px */
  --ui-button-medium-border-radius: 1.5625rem; /* 25px */

  --ui-button-small-padding: 0.75rem 1.25rem; /* 12px 20px */
  --ui-button-small-font-size: 0.75rem; /* 12px */
  --ui-button-small-font-weight: 600;
  --ui-button-small-line-height: 1rem; /* 16px */
  --ui-button-small-border-radius: 1.25rem; /* 20px */

  --ui-button-text-font-weight: 400;
  --ui-button-text-border-radius: 0.25rem; /* 4px */

  height: auto;
  font-family: var(--font-family-secondary);
  border: none;
  & + .el-button {
    margin: 0;
  }
}
/* MEDIUM BUTTON  */
.ui-button.el-button:not(.el-button--large):not(.el-button--small) {
  /* medium size */
  padding: var(--ui-button-medium-padding);
  font-size: var(--ui-button-medium-font-size);
  font-weight: var(--ui-button-medium-font-weight);
  line-height: var(--ui-button-medium-line-height);
  &.is-text {
    padding: 0;
    font-weight: var(--ui-button-text-font-weight);
  }
  &.is-link {
    padding: 0;
  }
}
.ui-button.el-button:focus-visible,
.ui-button.el-button.is-text:focus-visible,
.ui-button.el-button.is-link:focus-visible {
  background-color: var(--el-button-bg-color);
  outline: 1px solid var(--el-button-outline-color);
  outline-offset: 0;
}
/* PRIMARY BUTTON  */
.ui-button.el-button.ui-button--color-primary {
  --el-button-bg-color: var(--brand-color-primary) !important;
  --el-button-text-color: var(--brand-color-text-primary) !important;
  --el-button-border-color: var(--brand-color-primary) !important;

  --el-button-hover-bg-color: var(--brand-color-primary-hover) !important;
  --el-button-hover-text-color: var(--brand-color-text-primary) !important;
  --el-button-hover-border-color: var(--brand-color-primary-hover) !important;

  --el-button-active-bg-color: var(--brand-color-primary-pressed) !important;
  --el-button-active-border-color: var(
    --brand-color-primary-pressed
  ) !important;

  --el-button-disabled-text-color: var(--brand-color-text-tertiary) !important;
  --el-button-disabled-bg-color: var(--brand-color-primary-disabled) !important;
  --el-button-disabled-border-color: var(
    --brand-color-primary-disabled
  ) !important;

  --el-button-outline-color: var(--brand-color-primary-focus) !important;
  --el-button-active-color: var(--brand-color-primary-pressed) !important;
  --el-button-active-text-color: var(--brand-color-text-primary) !important;
}
/* SECONDARY BUTTON  */
.ui-button.el-button.ui-button--color-secondary {
  --el-button-bg-color: var(--brand-color-secondary) !important;
  --el-button-text-color: var(--brand-color-text-primary) !important;
  --el-button-border-color: var(--brand-color-secondary) !important;

  --el-button-hover-bg-color: var(--brand-color-secondary-hover) !important;
  --el-button-hover-text-color: var(--brand-color-text-primary) !important;
  --el-button-hover-border-color: var(--brand-color-secondary-hover) !important;

  --el-button-active-bg-color: var(--brand-color-secondary-pressed) !important;
  --el-button-active-border-color: var(
    --brand-color-secondary-pressed
  ) !important;

  --el-button-disabled-text-color: var(--brand-color-text-tertiary) !important;
  --el-button-disabled-bg-color: var(
    --brand-color-secondary-disabled
  ) !important;
  --el-button-disabled-border-color: var(
    --brand-color-secondary-disabled
  ) !important;

  --el-button-outline-color: var(--brand-color-secondary-focus) !important;
  --el-button-active-color: var(--brand-color-secondary-pressed) !important;
  --el-button-active-text-color: var(--brand-color-text-primary) !important;
}
/* LARGE BUTTON  */
.ui-button.el-button.el-button--large {
  padding: var(--ui-button-large-padding);
  font-size: var(--ui-button-large-font-size);
  font-weight: var(--ui-button-large-font-weight);
  line-height: var(--ui-button-large-line-height);
  &.is-text {
    padding: 0;
    font-weight: var(--ui-button-text-font-weight);
  }
  &.is-link {
    padding: 0;
  }
}
/* SMALL BUTTON  */
.ui-button.el-button.el-button--small {
  padding: var(--ui-button-small-padding);
  font-size: var(--ui-button-small-font-size);
  font-weight: var(--ui-button-small-font-weight);
  line-height: var(--ui-button-small-line-height);
  &.is-text {
    padding: 0;
    font-weight: var(--ui-button-text-font-weight);
  }
  &.is-link {
    padding: 0;
  }
}
/* TEXT BUTTON  */
.ui-button.el-button.is-text {
  --el-button-bg-color: transparent !important;
  --el-button-text-color: var(--brand-color-text-secondary) !important;
  --el-button-border-color: transparent !important;
  --el-button-hover-bg-color: transparent !important;
  --el-button-hover-text-color: var(--brand-color-text-primary) !important;
  --el-button-hover-link-text-color: var(--brand-color-text-primary) !important;
  --el-button-hover-border-color: transparent !important;
  --el-button-active-bg-color: transparent !important;
  --el-button-active-border-color: transparent !important;
  --el-button-disabled-text-color: var(--brand-color-text-tertiary) !important;
  --el-button-disabled-bg-color: transparent !important;
  --el-button-disabled-border-color: transparent !important;
  --el-button-outline-color: var(--brand-color-text-tertiary) !important;
  --el-button-active-text-color: var(--brand-color-text-primary) !important;
  --el-button-active-color: var(--brand-color-text-primary) !important;
  padding: 0;
  font-family: var(--font-family-primary);
  font-weight: var(--ui-button-text-font-weight);
  border-radius: var(--ui-button-text-border-radius);
  &:hover:not(:disabled),
  &:active:not(:disabled) {
    color: var(--brand-color-text-primary);
    background-color: transparent;
    outline: none;
  }
}
/* LINK BUTTON */
.ui-button.el-button.is-link {
  --el-button-bg-color: transparent !important;
  --el-button-text-color: var(--brand-color-text-secondary) !important;
  --el-button-border-color: transparent !important;
  --el-button-hover-bg-color: transparent !important;
  --el-button-hover-text-color: var(--brand-color-text-secondary) !important;
  --el-button-hover-link-text-color: var(
    --brand-color-text-secondary
  ) !important;
  --el-button-hover-border-color: transparent !important;
  --el-button-active-bg-color: transparent !important;
  --el-button-active-border-color: transparent !important;
  --el-button-disabled-text-color: var(--brand-color-base-secondary) !important;
  --el-button-disabled-bg-color: transparent !important;
  --el-button-disabled-border-color: transparent !important;
  --el-button-outline-color: var(--brand-color-text-tertiary) !important;
  --el-button-active-text-color: var(--brand-color-text-primary) !important;
  --el-button-active-color: var(--brand-color-text-primary) !important;

  padding: 0;
  font-family: var(--font-family-secondary);
  border-radius: var(--ui-button-text-border-radius);
}
.ui-button.el-button.ui-button--underline {
  text-decoration: underline;
}
/* ROUND BUTTON  */
.ui-button.el-button.is-round {
  border-radius: var(--ui-button-medium-border-radius);
  &.el-button--large {
    border-radius: var(--ui-button-large-border-radius);
  }
  &.el-button--small {
    border-radius: var(--ui-button-small-border-radius);
  }
  &.is-text,
  &.is-link {
    border-radius: var(--ui-button-text-border-radius);
  }
}
/* FULLWIDTH BUTTON  */
.ui-button.el-button.ui-button--fullwidth {
  width: 100%;
}
@media screen and (max-width: 1279px) {
  .ui-button.el-button:not(.el-button--large):not(
      .el-button--small
    ).ui-button--responsive {
    /* medium -> small */
    padding: var(--ui-button-small-padding);
    font-size: var(--ui-button-small-font-size);
    font-weight: var(--ui-button-small-font-weight);
    line-height: var(--ui-button-small-line-height);
    &.is-text {
      padding: 0;
      font-weight: var(--ui-button-text-font-weight);
    }
    &.is-link {
      padding: 0;
    }
  }
  .ui-button.el-button.el-button--large.ui-button--responsive {
    /* large -> medium */
    padding: var(--ui-button-medium-padding);
    font-size: var(--ui-button-medium-font-size);
    font-weight: var(--ui-button-medium-font-weight);
    line-height: var(--ui-button-medium-line-height);
    &.is-text {
      padding: 0;
      font-weight: var(--ui-button-text-font-weight);
    }
    &.is-link {
      padding: 0;
    }
  }
}
@media screen and (max-width: 767px) {
  .ui-button.el-button.el-button--large.ui-button--responsive {
    /* large -> medium -> small */
    padding: var(--ui-button-small-padding);
    font-size: var(--ui-button-small-font-size);
    font-weight: var(--ui-button-small-font-weight);
    line-height: var(--ui-button-small-line-height);
    &.is-text {
      padding: 0;
      font-weight: var(--ui-button-text-font-weight);
    }
    &.is-link {
      padding: 0;
    }
  }
}
</style>
